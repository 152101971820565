import React, { useState } from 'react'
import Breadcrumb from '../../../../Components/Breadcrumb/index'
import PageLoading from '../../../../Components/PageLoading/PageLoading'
import List from '../../../../Components/List/index'
import gql from 'graphql-tag'
import { useQuery, useLazyQuery } from '@apollo/react-hooks'
import CreateNew from '../../../../Components/CreateNew/index'
// import { useQuery, useLazyQuery } from 'react-apollo'

const GET_BUSINESSES = gql`
  query filterBusiness(
    $column: BusinessColumn
    $operator: SQLOperator
    $value: Mixed
    $first: Int!
    $page: Int!
  ) {
    filter_businesses(
      where: { column: $column, operator: $operator, value: $value }
      orderBy: [{ field: ID, order: DESC }]
      first: $first
      page: $page
    ) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
      data {
        id
        user_id
        name
        slug
        type
        employees
        turnover
        designation
        rc_no
        bn_no
        tin
        vat_reg_no
        address
        tier
        verified
        created_at
        updated_at
        subscribed
        cac_docs {
          files
        }
      }
    }
  }
`

const TURN_OVER_RANGE = gql`
  query turnOverRange {
    turnover_range {
      min
      max
    }
  }
`
const Business = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [filtered, setFiltered] = useState({
    column: 'TYPE',
    operator: 'NOT_IN',
    value: []
  })
  let pageLoading = false
  const [searchQuery] = useState({
    column: 'NAME',
    operator: 'LIKE',
    search: ''
  })

  let businesses = []
  let listPagination = {}

  const { loading: businessLoading, error: businessError, data } = useQuery(
    GET_BUSINESSES,
    {
      variables: {
        column: filtered.column,
        operator: filtered.operator,
        value: filtered.value,
        first: 15,
        page: currentPage
      },
      fetchPolicy: 'network-only'
    }
  )
  const {
    loading: turnOverLoading,
    data: turnOverData,
    error: turnOverError
  } = useQuery(TURN_OVER_RANGE)
  if (turnOverLoading) {
    pageLoading = true
  }
  if (turnOverError) {
    // console.log(turnOverError)
  }
  let turnOverRange = []

  let turnOver = {}
  if (turnOverData && turnOverData.turnover_range) {
    turnOver = turnOverData.turnover_range
    turnOverRange = [turnOver.min, turnOver.max]
  }

  // console.log(turnOver)
  const [filterData, { loading: filterLoading, error }] = useLazyQuery(
    GET_BUSINESSES,
    {
      fetchPolicy: 'network-only'
    }
  )
  const [searchData, { loading: searchLoading, data: searches }] = useLazyQuery(
    GET_BUSINESSES,
    {
      fetchPolicy: 'network-only'
    }
  )

  // useEffect(() => {
  //   clearedData({
  //     variables:{
  //       page: currentPage,
  //       first: 15
  //     }
  //   })
  // }, [])

  if (businessLoading) {
    pageLoading = true
  }

  if (businessError) {
    return (
      <div className="dashform-box">
        <div className="dashform">
          <div className="dashform-intro text-center">
            Well, this is embarrassing. Something went wrong. Please try again later.
          </div>
        </div>
      </div>
    )
  }

  if (data && data.filter_businesses) {
    businesses = data.filter_businesses.data
    listPagination = data.filter_businesses.paginatorInfo
  }
  if (error) {
    return (
      <div className="dashform-box">
        <div className="dashform">
          <div className="dashform-intro text-center">
            Well, this is embarrassing. Something went wrong. Please try again later.
          </div>
        </div>
      </div>
    )
  }
  const filter = [
    {
      title: 'TYPE',
      items: ['Limited Liability Company', 'Partnership', 'Sole Proprietorship']
    },
    {
      title: 'TIER',
      items: ['1', '2', '3'] //filtered group
    },
    {
      title: 'TURNOVER',
      items: turnOverRange,
      value: { min: Number(turnOverRange[0]), max: Number(turnOverRange[1]) }
    }
  ]

  if (filterLoading) {
    pageLoading = true
  }
  if (searches) {
    businesses = searches.filter_businesses.data
    listPagination = searches.filter_businesses.paginatorInfo
  }

  const handleFilter = (event, type) => {
    let newFiltered = { ...filtered }
    let filteredValue = filtered.column === type ? newFiltered.value : []

    let operator
    if (type === 'TURNOVER') {
      // console.log(event)
      operator = 'BETWEEN'
      filteredValue = [event.min, event.max]
    } else if (type === 'TYPE' || type === 'TIER') {
      const index = filteredValue.indexOf(event.target.value)
      operator = 'IN'
      if (index > -1) {
        filteredValue.splice(index, 1)
      } else {
        filteredValue.push(event.target.value)
      }
    }
    if (filteredValue.length < 1) {
      operator = 'NOT_IN'
    }
    setFiltered({
      column: type,
      operator: operator,
      value: filteredValue
    })
    filterData({
      variables: {
        column: type,
        operator: operator,
        value: filteredValue,
        first: 15,
        page: 1
      },
      onCompleted: data => {
        businesses = data.filter_businesses.data
        listPagination = data.filter_businesses.paginatorInfo
      }
    })
  }
  const clearFilter = () => {
    setFiltered({
      column: 'TYPE',
      operator: 'NOT_IN',
      value: []
    })
  }

  const handleSearch = (event, query) => {
    event.preventDefault()
    searchQuery.search = `%${query}%`
    searchData({
      variables: {
        column: searchQuery.column,
        operator: searchQuery.operator,
        value: searchQuery.search,
        first: 5,
        page: 1
      }
    })
  }
  if (searchLoading) {
    pageLoading = true
  }
  const handlePagination = page => {
    setCurrentPage(page)
  }

  return (
    <div>
      <Breadcrumb name="Businesses" />
      <CreateNew label="Total businesses" total={listPagination.total} />
      <div>
        <List
          type="businesses"
          title="All Businesses"
          list={businesses}
          pagination={listPagination}
          pageClicked={handlePagination}
          filter={filter}
          filteredItems={filtered.value}
          filterClicked={handleFilter}
          clear={clearFilter}
          search={handleSearch}
          turnOver={turnOverRange}
        />
      </div>
      {pageLoading ? <PageLoading ripple={true} /> : null}
    </div>
  )
}
export default Business
