import React, { useState } from 'react'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import BreadCrumb from '../../../Components/Breadcrumb'
import PageLoading from '../../../Components/PageLoading/PageLoading'
import { Link } from 'react-router-dom'

const CREATE_PLAN = gql`
  mutation CreatePlan($input: createOrUpdatePlanInput!) {
    create_plan(input: $input) {
      id
      name
      plan_code
      description
      amount
      interval
    }
  }
`

const PLANS = gql`
  query {
    plans {
      id
      name
      plan_code
      amount
      interval
    }
  }
`
const CreatePlan = () => {
  const [plan, setPlan] = useState({
    name: '',
    description: '',
    amount: 0.0,
    interval: 'monthly',
    isValid: false
  })
  
  const [createPlan, { data, error, loading }] = useMutation(CREATE_PLAN, {
    refetchQueries: [{ query: PLANS }]
  })
  
  let pageLoading = false
  let isPlan = false

  const checkValidity = () => {
    let valid = true
    valid = plan.name.trim() !== '' && valid
    valid = plan.description.trim() !== '' && valid
    valid = plan.amount !== 0 && valid
    valid = plan.interval.trim() !== '' && valid
    return valid
  }

  const handleFormChange = e => {
    setPlan({
      ...plan,
      [e.target.name]: e.target.value,
      isValid: checkValidity()
    })
  }

  if (loading) {
    pageLoading = true
  }

  if (error) {
    console.log(error)
  }

  if (data) {
    isPlan = true
  }

  const handleSubmit = e => {
    e.preventDefault()
    if (plan.amount < 100) {
      return
    }

    const planDetails = {
      name: plan.name,
      description: plan.description,
      amount: Number(plan.amount),
      interval: plan.interval,
    }

    createPlan({
      variables: {
        input: planDetails
      }
    })
  }

  return (
    <div>
      <BreadCrumb name="Create Plan" />
      {!isPlan ? (
        <form className="dashform-box w-50" onSubmit={handleSubmit}>
          <div className="dashform update-profile">
            <div className="dashform-intro">Create Plan</div>

            <label>Name</label>
            <input
              type="text"
              value={plan.name}
              onChange={handleFormChange}
              required
              name="name"
            />

            <label>Description</label>
            <textarea
              value={plan.description}
              onChange={handleFormChange}
              required
              name="description"
            ></textarea>
            
            <label>
              Amount<span className="naira bold">(&#8358;)</span>
            </label>
            <input
              type="number"
              value={plan.amount}
              onChange={handleFormChange}
              required
              name="amount"
            />

            <label>
              Interval
              <select
                className="selector"
                onChange={handleFormChange}
                onBlur={handleFormChange}
                value={plan.interval}
                name="interval"
                required
              >
                <option value="hourly"> Hourly</option>
                <option value="daily"> Daily</option>
                <option value="weekly"> Weekly</option>
                <option value="monthly"> Monthly</option>
                <option value="biannually"> Biannually</option>
                <option value="annually"> Annually</option>
              </select>
            </label>

            <div className="submit-box">
              <button type="submit" disabled={!plan.isValid}>
                Submit
              </button>
            </div>
          </div>
        </form>

      ) : (
        
        <div className="dashform-box">
          <div className="dashform">
            <div className="dashform-intro text-center">
              Plan created successfully
            </div>

            <div className="dashform-message">
              Go back to
              <Link to="/plans" className="link a">
                Plans
              </Link>
            </div>
          </div>
        </div>
      )}

      {pageLoading ? <PageLoading ripple={true} /> : null}
    </div>
  )
}

export default CreatePlan
