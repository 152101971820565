import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import Breadcrumb from '../../../../Components/Breadcrumb/index'
import PageLoading from '../../../../Components/PageLoading/PageLoading'

const ONE_BUSINESS = gql`
  query one_Business($slug: String!) {
    business(slug: $slug) {
      id
      user_id
      name
      slug
      type
      employees
      turnover
      designation
      subscribed
      rc_no
      bn_no
      tin
      vat_reg_no
      address
      tier
      phone
      verified
      created_at
      updated_at
      cac_docs {
        files
      }
      user {
        first_name
        last_name
        email
        identity
      }
    }
  }
`

const SingleBusiness = props => {
  const { slug } = props.match.params
  let pageLoading = false
  const { loading, error, data } = useQuery(ONE_BUSINESS, {
    variables: { slug: slug }
  })

  if (loading) {
    pageLoading = true
  }

  if (error) {
    return (
      <div className="dashform-box">
        <div className="dashform">
          <div className="dashform-intro text-center">
            Well, this is embarrassing. Something went wrong. Please try again later.
          </div>
        </div>
      </div>
    )
  }
  let documents = []

  let business = {}

  if (data && business) {
    business = data.business;

    const docsArray = [];
    business.cac_docs.forEach(element => {
        docsArray.push(JSON.parse(element.files));
    });
    
    const files = business.cac_docs[0] ? Object.assign(...docsArray, {}) : null;
    if (files) {
      for (const key in files) {
          documents.push({
              name: key, path: files[key]
          })
      }
    }
    documents.reverse();
  }

  return (
    <div className="customer-box">
      {data && (
        <>
          <Breadcrumb name="Business" />
          <div className="profile-box">
            <div className="profile">
              <div className="profile-intro">
                <div className="desc big">
                  <div className="label">Business name</div>
                  <div className="value">
                    {' '}
                    {business.name ? business.name : 'N/A'}
                  </div>
                </div>
                <div className="desc wide">
                  <div className="label">Business address</div>
                  <div className="value editable">
                    {' '}
                    {business.address ? business.address : 'N/A'}
                  </div>
                </div>

                <div className="desc wide">
                  <div className="label">Email</div>
                  <div className="value editable">
                    {business.user.email ? business.user.email : 'N/A'}
                  </div>
                </div>
                {business.user.first_name && business.user.last_name && (
                  <div className="desc wide-mob">
                    <div className="label">User</div>
                    <div className="value editable">
                      {business.user.first_name} {business.user.last_name}
                    </div>
                  </div>
                )}
                <div className="desc wide-mob">
                  <div className="label">Phone number</div>
                  <div className="value editable">
                    {business.phone ? business.phone : 'N/A'}
                  </div>
                </div>
                <div className="desc wide-mob">
                  <div className="label">Yearly turnover</div>
                  <div className="value editable">
                    <span className="naira">&#8358;</span>
                    {business.turnover ? business.turnover : 'N/A'}
                  </div>
                </div>
                <div className="desc">
                  <div className="label">Business type</div>
                  <div className="value editable">
                    {business.type ? business.type : 'N/A'}
                  </div>
                </div>
                <div className="desc">
                  <div className="label">Employees</div>
                  <div className="value">{business.employees}</div>
                </div>
                {business.rc_no ? (
                  <div className="desc">
                    <div className="label">RC number</div>
                    <div className="value">{business.rc_no}</div>
                  </div>
                ) : null}

                {business.bn_no ? (
                  <div className="desc">
                    <div className="label">BN number</div>
                    <div className="value">{business.bn_no}</div>
                  </div>
                ) : null}

                <div className="desc">
                  <div className="label">TIN</div>
                  <div className="value">
                    {business.tin ? business.tin : 'N/A'}
                  </div>
                </div>
                <div className="desc">
                  <div className="label">VAT number</div>
                  <div className="value">{business.vat_reg_no}</div>
                </div>
              </div>
              <div className="profile-text">
                {documents &&
                  documents.map(document => {
                    return (
                      <div className="profile-assessment" key={document.name}>
                        <div className="question">{document.name}</div>
                        <div className="answer">
                          <a
                            target="_blank"
                            href={document.path}
                            rel="noopener noreferrer"
                          >
                            Download <i className="la la-download"></i>
                          </a>
                        </div>
                      </div>
                    )
                  })}
              </div>
            </div>
          </div>
        </>
      )}
      {pageLoading ? <PageLoading ripple={true} /> : null}
    </div>
  )
}

export default SingleBusiness
