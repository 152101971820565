import React, { useState } from 'react'
import Breadcrumb from '../../../Components/Breadcrumb'
import List from '../../../Components/List'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import PageLoading from '../../../Components/PageLoading/PageLoading'
import CreateNew from '../../../Components/CreateNew'

const ALL_ADMINS = gql`
  query all_Admins($first: Int!, $page: Int!) {
    admins(first: $first, page: $page) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
        __typename
      }
      data {
        id
        first_name
        last_name
        email
        created_at
        updated_at

        roles {
          name
        }
      }
    }
  }
`

const AllAdmins = () => {
  const [currentPage, setCurrentPage] = useState(1)
  let pageLoading = false
  const { loading, error, data } = useQuery(ALL_ADMINS, {
    variables: { first: 10, page: currentPage },
    fetchPolicy: 'cache-and-network'
  })

  if (loading) {
    pageLoading = true
  }
  if (error) {
    return (
      <div className="dashform-box">
        <div className="dashform">
          <div className="dashform-intro text-center">
            Well, this is embarrassing. Something went wrong. Please try again later.
          </div>
        </div>
      </div>
    )
  }

  let admins = []
  let listPagination = {}
  if (data && data.admins) {
    admins = data.admins.data
    listPagination = data.admins.paginatorInfo
  }
  const handlePagination = page => {
    setCurrentPage(page)
  }

  return (
    <div>
      <Breadcrumb name="Admins" />
      <CreateNew
        label="Total admins"
        total={listPagination.total}
        link="/admins/new"
      />
      <List
        type="super"
        title="All admins"
        list={admins}
        pagination={listPagination}
        pageClicked={handlePagination}
      />
      {pageLoading ? <PageLoading ripple={true} /> : null}
    </div>
  )
}

export default AllAdmins
