import React, { Component } from 'react'
import './index.css'
import NavItem from './NavItem'

class nav extends Component {
  render() {
    return (
      <div className="nav">
        <NavItem icon="home" label="Home" url="/home" />

        <NavItem icon="briefcase" label="Businesses" url="/businesses" />

        <NavItem icon="users" label="Consultants" url="/consultants" />

        <NavItem icon="comments" label="Supports" url="/support" />
        <NavItem
          icon="file-medical-alt"
          label="Health Requests"
          url="/tax-health-requests"
        />
        <NavItem
          icon="calculator"
          label="Computation Requests"
          url="/tax-computation-requests"
        />
        <NavItem icon="user" label="Users" url="/unregistered-users" />
        {/* <NavItem icon="cogs" label="Settings" url="/password/reset" /> */}
      </div>
    )
  }
}

export default nav
