import React, { useState } from 'react'
import Breadcrumb from '../../../Components/Breadcrumb'
import List from '../../../Components/List'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import PageLoading from '../../../Components/PageLoading/PageLoading'

const UNVERIFIED_USERS = gql`
  query all_users($first: Int!, $page: Int!) {
    users(first: $first, page: $page) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
      data {
        id
        first_name
        last_name
        email
      }
    }
  }
`

export const Users = () => {
  const [currentPage, setCurrentPage] = useState(1)
  let pageLoading = false
  const { loading, error, data } = useQuery(UNVERIFIED_USERS, {
    variables: { first: 10, page: currentPage }
  })

  if (loading) {
    pageLoading = true
  }

  if (error) {
    return (
      <div className="dashform-box">
        <div className="dashform">
          <div className="dashform-intro text-center">
            Well, this is embarrassing. Something went wrong. Please try again later.
          </div>
        </div>
      </div>
    )
  }

  let users = []
  let listPagination = {}
  if (data && data.users) {
    users = data.users.data
    listPagination = data.users.paginatorInfo
  }

  const handlePagination = page => {
    setCurrentPage(page)
  }

  return (
    <div>
      <Breadcrumb name="Users" />
      <List
        type="users"
        title="Unverified users"
        list={users}
        pagination={listPagination}
        pageClicked={handlePagination}
      />
      {pageLoading ? <PageLoading ripple={true} /> : null}
    </div>
  )
}
