import React from 'react'
// import './Dashboard.css';
import { Route, Switch } from 'react-router-dom'
import Home from './Home'

import Consultants from './Consultants'
import NewConsultant from './Consultants/New'
import AdminNav from './Nav'
import AllAdmins from './Admins/All'
import NewAdmin from './Admins/New'
import AllBusiness from './Business/All'
import SingleConsultant from '../Admin/Consultants/Single/index'

import SingleAdmin from './Admins/Single'
import NewPassword from './Password/PasswordChange'
import OneBusiness from './Business/One'

import Assignments from '../Shared'
import Support from '../Shared/Support/index'
import SupportTicket from '../Shared/Support/One'
import PageNotFound from '../../Components/PageNotFound/PageNotFound'
// import OneConsultant from './Consultants/One'
import Plans from './Plans/All'
import SinglePlan from './Plans/One'
import CreatePlan from './Plans/Create'
import EditPlan from './Plans/Edit'
import Transactions from './Transactions'
import Subscriptions from './Subscriptions'
import TaxHealthRequests from './TaxHealthRequests'
import TaxComputationRequests from './TaxComputation'
import { Users } from './users'
import EditConsultant from './Consultants/Edit'
import CreateSubcription from './Subscriptions/create'
const SuperAdmin = props => {
  return (
    <>
      <AdminNav />
      <Switch>
        <Route path="/(|home)/" exact component={Home} />
        {/*Customers/Business routes*/}
        <Route path="/businesses" exact component={AllBusiness} />
        <Route
          path="/businesses/:slug"
          exact
          render={props => <OneBusiness {...props} />}
        />

        {/*Consultants routes */}

        <Route path="/consultants" exact component={Consultants} />

        <Route path="/consultants/new" exact component={NewConsultant} />
        <Route
          path="/consultants/:id"
          exact
          render={props => <SingleConsultant {...props} />}
        />

        <Route
          path="/consultants/:id/edit"
          exact
          component={props => <EditConsultant {...props} />}
        />

        {/*admin route */}
        <Route
          path="/admins"
          exact
          render={props => <AllAdmins {...props} />}
        />
        <Route
          path="/admins/new"
          exact
          render={props => <NewAdmin {...props} />}
        />
        <Route
          path="/admins/:id"
          exact
          render={props => <SingleAdmin {...props} />}
        />

        <Route exact path="/assignments" component={Assignments} />
        {/* Password Change*/}
        <Route exact path="/password-change" component={NewPassword} />
        {/* Support route */}
        <Route exact path="/support" render={props => <Support {...props} />} />
        <Route
          path="/support/:id"
          exact
          render={props => <SupportTicket {...props} />}
        />
        {/* Plans route */}
        <Route exact path="/plans" render={props => <Plans {...props} />} />
        <Route exact path="/plans/new" component={CreatePlan} />
        <Route
          exact
          path="/plans/:id"
          render={props => <SinglePlan {...props} />}
        />
        <Route
          exact
          path="/plans/:id/edit"
          render={props => <EditPlan {...props} />}
        />
        <Route
          exact
          path="/transactions"
          render={props => <Transactions {...props} />}
        />
        <Route
          exact
          path="/subscriptions"
          render={props => <Subscriptions {...props} />}
        />
        <Route
          exact
          path="/subscriptions/create"
          render={props => <CreateSubcription {...props} />}
        />
        <Route
          exact
          path="/tax-health-requests"
          component={TaxHealthRequests}
        />
        <Route
          exact
          path="/tax-computation-requests"
          component={TaxComputationRequests}
        />
        <Route
          exact
          path="/password/reset"
          render={props => <NewPassword {...props} />}
        />

        <Route
          exact
          path="/unregistered-users"
          render={props => <Users {...props} />}
        />

        <Route path="*" component={PageNotFound} />
      </Switch>
    </>
  )
}

export default SuperAdmin
