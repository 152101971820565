import React, { useState } from 'react'
import BreadCrumb from '../../../Components/Breadcrumb/index'
import CreateNew from '../../../Components/CreateNew/index'
import List from '../../../Components/List'
import gql from 'graphql-tag'
import { useQuery, useLazyQuery } from '@apollo/react-hooks'
import PageLoading from '../../../Components/PageLoading/PageLoading'
const SUBSCRIPTIONS = gql`
  query allSubscriptions($month: String, $first: Int!, $page: Int!) {
    subscriptions(month: $month, first: $first, page: $page) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
      data {
        business
        code
        token
        start
        end
        plan
        status
        amount
      }
    }
  }
`
const Subscriptions = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [monthFilter, setMonthFilter] = useState([])
  let pageLoading = false
  const { loading, data, error } = useQuery(SUBSCRIPTIONS, {
    variables: {
      month: '',
      first: 10,
      page: currentPage
    }
  })
  if (loading) {
    pageLoading = true
  }

  let subscriptions = []
  let listPagination = {}

  if (data && data.subscriptions) {
    subscriptions = data.subscriptions.data
    listPagination = data.subscriptions.paginatorInfo
  }

  const [
    filteredData,
    { loading: filterLoading, data: filtered, error: filteredError }
  ] = useLazyQuery(SUBSCRIPTIONS, {
    fetchPolicy: 'network-only'
  })
  if (error) {
    return (
      <div className="dashform-box">
        <div className="dashform">
          <div className="dashform-intro text-center">
            Well, this is embarrassing. Something went wrong. Please try again later.
          </div>
        </div>
      </div>
    )
  }
  if (filterLoading) {
    pageLoading = true
  }
  if (filteredError) console.log(filteredError)
  if (filtered) {
    subscriptions = filtered.subscriptions.data
    listPagination = filtered.subscriptions.paginatorInfo
  }
  const filter = [
    {
      title: 'MONTHS',
      items: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
      ]
    }
  ]
  const handlePagination = page => {
    setCurrentPage(page)
  }
  const clearFilter = () => {
    filteredData({
      variables: {
        month: '',
        first: 10,
        page: 1
      }
    })
  }
  const handleFilter = month => {
    filteredData({
      variables: {
        month: month,
        first: 10,
        page: 1
      }
    })
    setMonthFilter([month])
  }
  return (
    <div>
      <BreadCrumb name="Subscriptions" />
      <CreateNew label="Create subcriptions" link="/subscriptions/create" />
      <List
        type="subscriptions"
        title="All subscriptions"
        list={subscriptions}
        pagination={listPagination}
        pageClicked={handlePagination}
        filter={filter}
        filterClicked={handleFilter}
        filteredItems={monthFilter}
        clear={clearFilter}
      />
      {pageLoading ? <PageLoading ripple={true} /> : null}
    </div>
  )
}
export default Subscriptions
