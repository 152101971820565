import React, { useState } from 'react'
import BreadCrumb from '../../../Components/Breadcrumb/index'
import List from '../../../Components/List'
import gql from 'graphql-tag'
import { useQuery, useLazyQuery } from '@apollo/react-hooks'
import PageLoading from '../../../Components/PageLoading/PageLoading'

const TRANSACTIONS = gql`
  query allTransactions($month: String, $first: Int!, $page: Int!) {
    transactions(month: $month, first: $first, page: $page) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
      data {
        reference
        business
        plan
        plan_status
        amount
        status
        date
      }
    }
  }
`
const Transactions = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [monthFilter, setMonthFilter] = useState([])
  let pageLoading = false
  const { loading, error, data } = useQuery(TRANSACTIONS, {
    variables: {
      month: '',
      first: 10,
      page: currentPage
    }
  })
  if (loading) {
    pageLoading = true
  }

  let transactions = []
  let listPagination = {}
  if (data && data.transactions) {
    transactions = data.transactions.data
    listPagination = data.transactions.paginatorInfo
    // console.log(data)
  }

  const [
    filteredData,
    { loading: filterLoading, data: filtered, error: filteredError }
  ] = useLazyQuery(TRANSACTIONS, {
    fetchPolicy: 'network-only'
  })
  if (error) {
    return (
      <div className="dashform-box">
        <div className="dashform">
          <div className="dashform-intro text-center">
            Well, this is embarrassing. Something went wrong. Please try again later.
          </div>
        </div>
      </div>
    )
  }

  if (filterLoading) {
    pageLoading = true
  }
  if (filteredError) console.log(filteredError)
  if (filtered) {
    transactions = filtered.transactions.data
    listPagination = filtered.transactions.paginatorInfo
  }
  const handlePagination = page => {
    setCurrentPage(page)
  }
  const filter = [
    {
      title: 'MONTHS',
      items: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
      ]
    }
  ]

  const clearFilter = () => {
    filteredData({
      variables: {
        month: '',
        first: 10,
        page: 1
      }
    })
  }
  const handleFilter = month => {
    filteredData({
      variables: {
        month: month,
        first: 10,
        page: 1
      }
    })
    setMonthFilter([month])
  }

  return (
    <div>
      <BreadCrumb name=" Transactions " />
      <List
        type="transactions"
        title="All transactions "
        list={transactions}
        pagination={listPagination}
        pageClicked={handlePagination}
        filter={filter}
        filterClicked={handleFilter}
        filteredItems={monthFilter}
        clear={clearFilter}
      />
      {pageLoading ? <PageLoading ripple={true} /> : null}
    </div>
  )
}

export default Transactions
