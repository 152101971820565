import React from 'react'

import gql from 'graphql-tag'
import { useQuery, useMutation } from '@apollo/react-hooks'
import PageLoading from '../../../../../Components/PageLoading/PageLoading'

const GET_ASSIGNABLE_CONSULTANTS = gql`
  query assignableConsultants($tier: String!) {
    assignable_consultants(tier: $tier) {
      id
      first_name
      last_name
      assignments
    }
  }
`
const VERIFY_BUSINESS = gql`
  mutation verify_Business($business_slug: String!, $consultant_id: Int!) {
    verify_business(
      business_slug: $business_slug
      consultant_id: $consultant_id
    ) {
      verified
    }
  }
`
const ONE_BUSINESS = gql`
  query one_Business($slug: String!) {
    business(slug: $slug) {
      id
      user_id
      name
      slug
      type
      employees
      turnover
      designation
      subscribed
      rc_no
      bn_no
      tin
      vat_reg_no
      address
      tier
      verified
      created_at
      updated_at
      cac_docs {
        files
      }
    }
  }
`
const AssignableConsultants = props => {
  const { tier, businessSlug, businessId } = props
  console.log(businessId)
  const { loading, data } = useQuery(GET_ASSIGNABLE_CONSULTANTS, {
    variables: { tier: tier }
  })
  let pageLoading = false
  const [
    verifyBusiness,
    { data: assignData, loading: assignLoading }
  ] = useMutation(VERIFY_BUSINESS, {
    refetchQueries: [{ query: ONE_BUSINESS, variables: { slug: businessSlug } }]
  })

  let isAssigned = false
  if (loading) {
    return (pageLoading = true)
  }
  if (assignData) {
    isAssigned = true
  }
  if (assignLoading) {
    pageLoading = true
  }

  if (data) {
    console.log(data)
  }

  const { assignable_consultants } = data

  return (
    <>
      <div className="modal-intro">
        <b>Select a consultant</b> for{' '}
        <b className="name">{props.businessName}</b>
        <div className="modal-headers">
          <div className="w-50">Name</div>
          <div className="w-20">Businesses</div>
        </div>
      </div>
      <div className="modal-list">
        {!isAssigned ? (
          <>
            {assignable_consultants.map((consultant, key) => (
              <div className="modal-list-item" key={key}>
                <div className="w-50">
                  {consultant.first_name} {consultant.last_name}
                </div>
                <div className="w-20">{consultant.assignments}</div>
                <div className="action w-30">
                  <button
                    onClick={() => {
                      verifyBusiness({
                        variables: {
                          business_slug: businessSlug,
                          consultant_id: consultant.id
                        }
                      })
                    }}
                  >
                    Select
                  </button>
                </div>
              </div>
            ))}
          </>
        ) : (
          <div className="dashform-box">
            <div className="dashform">
              <div className="dashform-message">
                Verified
                <br />
                <br />
                <br />
              </div>
            </div>
          </div>
        )}
      </div>
      {pageLoading ? <PageLoading ripple={true} /> : null}
    </>
  )
}

export default AssignableConsultants
