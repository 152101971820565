import React from 'react'
import { Route, Switch } from 'react-router-dom'
import AdminHome from './Home'
import OneBusiness from './Businesses/All/Single'
import Nav from '../../Components/Nav/index'
import Business from './Businesses/All'
import Consultants from './Consultants/All'
import Assignments from '../Shared/index'

import SingleConsultant from './Consultants/Single'
import NewTierBusinesses from './Businesses/All/ChangeTier'
import NewPassword from '../SuperAdmin/Password/PasswordChange'
import Support from '../Shared/Support/index'
import PageNotFound from '../../Components/PageNotFound/PageNotFound'
import SupportTicket from '../Shared/Support/One'
import TaxHealthRequests from '../SuperAdmin/TaxHealthRequests'
import TaxComputationRequests from '../SuperAdmin/TaxComputation'
import { Users } from '../SuperAdmin/users'
import EditConsultant from '../SuperAdmin/Consultants/Edit'
class Admin extends React.Component {
  // state = {
  //   allBusinesses: BusinessList,
  //   customer: null
  // }

  render() {
    return (
      <div className="admin">
        <Nav />
        <Switch>
          <Route path="/(|home)/" exact component={AdminHome} />
          {/* admin-businesses and business types route*/}
          <Route
            path="/businesses"
            exact
            render={props => <Business {...props} />}
          />
          {/* <Route path="/businesses/tier" exact component={TierOneBusiness} /> */}
          <Route
            path="/businesses/:slug"
            exact
            render={props => <OneBusiness {...props} />}
          />

          {/* admin-consultant routes */}
          <Route
            path="/consultants"
            exact
            render={props => <Consultants {...props} />}
          />
          <Route
            path="/consultants/:id"
            exact
            render={props => <SingleConsultant {...props} />}
          />
          <Route
            path="/consultants/:id/edit"
            exact
            render={props => <EditConsultant {...props} />}
          />

          <Route exact path="/assignments" component={Assignments} />
          <Route
            exact
            path="/tax-health-requests"
            component={TaxHealthRequests}
          />

          <Route
            exact
            path="/tax-computation-requests"
            component={TaxComputationRequests}
          />
          <Route exact path="/change-tier" component={NewTierBusinesses} />
          <Route
            path="/support"
            exact
            render={props => <Support {...props} />}
          />
          <Route
            path="/support/:id"
            exact
            render={props => <SupportTicket {...props} />}
          />
          <Route exact path="/unregistered-users" component={Users} />

          {/* <Route
            exact
            path="/password/reset"
            render={props => <NewPassword {...props} />}
        /> */}

          <Route path="*" component={PageNotFound} />
        </Switch>
      </div>
    )
  }
}

export default Admin
