import React, { useState } from 'react'
import List from '../../../../Components/List/index'
import Breadcrumb from '../../../../Components/Breadcrumb/index'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import PageLoading from '../../../../Components/PageLoading/PageLoading'

const GET_TAX_COMPUTATION = gql`
  query($first: Int!, $page: Int!) {
    list_tcf(first: $first, page: $page) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
      data {
        id
        type
        text_info
        documents
        report
        status
        period
        created_at
        updated_at
        business {
          id
          user_id
          name
          slug
          type
          employees
        }
      }
    }
  }
`
const ComputationRequests = () => {
  const [currentPage, setCurrentPage] = useState(1)
  let pageLoading = false
  const { loading, data, error } = useQuery(GET_TAX_COMPUTATION, {
    variables: {
      first: 10,
      page: currentPage
    }
  })
  if (loading) {
    pageLoading = true
  }
  if (error) {
    return (
      <div className="dashform-box">
        <div className="dashform">
          <div className="dashform-intro text-center">
            Well, this is embarrassing. Something went wrong. Please try again later.
          </div>
        </div>
      </div>
    )
  }

  let healthComputation = []
  let listPagination = {}
  if (data && data.list_tcf) {
    healthComputation = data.list_tcf.data
    listPagination = data.list_tcf.paginatorInfo
  }

  const handlePagination = page => {
    setCurrentPage(page)
  }

  return (
    <div>
      <Breadcrumb name="Pending Requests" />
      <List
        type="computations"
        title="Requests"
        list={healthComputation}
        pagination={listPagination}
        pageClicked={handlePagination}
      />
      {pageLoading ? <PageLoading ripple={true} /> : null}
    </div>
  )
}
export default ComputationRequests
