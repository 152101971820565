import React from 'react'
import NavItem from '../../../Components/Nav/NavItem/index'
import '../../../Components/Nav/index.css'
const ConsultantNav = () => {
  return (
    <div className="nav">
      <NavItem icon="home" label="Home" url="/home" />

      <NavItem icon="file-medical-alt" label="Tax Health" url="/tax-check" />
      <NavItem
        icon="calculator"
        label="Tax Computations"
        url="/computation-requests"
      />
      <NavItem icon="comments" url="/support" label="Support" />
      <NavItem icon="cogs" label="Settings" url="/password/reset" />
    </div>
  )
}

export default ConsultantNav
