import React,{useState} from 'react'
import Breadcrumb from '../../../Components/Breadcrumb'
import List from '../../../Components/List'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import PageLoading from '../../../Components/PageLoading/PageLoading'

const GET_TAX_HEALTH_CHECK = gql`
  query($first: Int!, $page: Int!) {
    list_tax_health(first: $first, page: $page) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
      data {
        id
        type
        created_at
        score
        report
        assessment
        business {
          name
          type
          address
        }
      }
    }
  }
`
const TaxHealthRequests = () => {
const [currentPage, setCurrentPage] = useState(1)
  let pageLoading = false
  const { loading, data, error } = useQuery(GET_TAX_HEALTH_CHECK, {
    variables: { first: 10, page: currentPage }
  })
  if (loading) {
    pageLoading = true
  }

  if (error) {
    console.log(error)
    return (
      <div className="dashform-box">
        <div className="dashform">
          <div className="dashform-intro text-center">
            Well, this is embarrassing. Something went wrong. Please try again later.
          </div>
        </div>
      </div>
    )
  }
  let healthCheckList = []
  let listPagination = {}
  if (data && data.list_tax_health) {
    healthCheckList = data.list_tax_health.data
    listPagination = data.list_tax_health.paginatorInfo
  }

  const handlePagination = page => {
    setCurrentPage(page)
  }


  return (
    <div>
      <Breadcrumb name="Tax Health Requests" />
      <List
        type="admin-requests"
        list={healthCheckList}
        title="Tax Health requests"
        pagination={listPagination}
        pageClicked={handlePagination}
      />
      {pageLoading ? <PageLoading ripple={true} /> : null}
    </div>
  )

}

export default TaxHealthRequests;