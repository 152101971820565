import React, { useState } from 'react'
import Breadcrumb from '../../../../Components/Breadcrumb/index'
import PageLoading from '../../../../Components/PageLoading/PageLoading'
import ReactHtmlParser from 'react-html-parser'
// import { Link } from 'react-router-dom'
import '../Business.css'
import { animateScroll } from 'react-scroll'
import CKEditor from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import gql from 'graphql-tag'
import { useQuery, useMutation } from '@apollo/react-hooks'

const REVIEW_HEALTH_CHECK = gql`
  query review_Tax_Health($id: Int!) {
    review_tax_health(id: $id) {
      id
      type
      assessment
      score
      report
      created_at
      updated_at

      business {
        id
        user_id
        name
        slug
        type
        employees
        turnover
        designation

        rc_no
        bn_no
        tin
        vat_reg_no
        address
        tier
        verified
        created_at
        updated_at
        cac_docs {
          files
        }
      }
    }
  }
`
const SCORE_TAX_HEALTH = gql`
  mutation scoreTaxHealth($id: Int!, $input: ScoreTaxInput!) {
    score_tax_health(id: $id, input: $input) {
      id
      type
      assessment
      score
      report
      created_at
      updated_at
      business {
        id
        user_id
        name
        slug
        type
        employees
        turnover
        designation

        rc_no
        bn_no
        tin
        vat_reg_no
        address
        tier
        verified
        created_at
        updated_at
        cac_docs {
          files
        }
      }
    }
  }
`

const SingleRequestDetails = props => {
  const [taxHealth, setTaxHealth] = useState({ report: '', isValid: false })
  // const [diffScore, setDiffScore] = useState('')
  let pageLoading = false

  const {
    match: {
      params: { id }
    }
  } = props

  const [
    scoreTaxHealth,
    { loading: scoreTaxLoading, error: scoreTaxError, data: scoreTaxData }
  ] = useMutation(SCORE_TAX_HEALTH)
  const {
    data: taxHealthData,
    loading: taxHealthLoading,
    error: taxHealthError
  } = useQuery(REVIEW_HEALTH_CHECK, {
    variables: { id: id }
  })
  // let isReviewSent = false
  if (taxHealthLoading) {
    return <PageLoading ripple={true} />
  }
  if (taxHealthError) {
    console.log(taxHealthError)
  }
  const { review_tax_health } = taxHealthData
  let documents = []
  let questions = []
  // let score = 0
  // let totalScore = 0
  if (taxHealthData) {
    const docs =
      review_tax_health.business.cac_docs.length > 0
        ? JSON.parse(review_tax_health.business.cac_docs[0].files)
        : []
    for (const key in docs) {
      documents.push({
        name: key,
        path: docs[key]
      })
    }
    if (review_tax_health.assessment) {
      const reviewAssesement = JSON.parse(review_tax_health.assessment)
      const review = JSON.parse(reviewAssesement)

      for (const key in review) {
        questions.push({
          question: key,
          answer: review[key]
        })
        // if (review[key] === 'Yes') {
        //   score++
        // }
      }
    }
  }

  const handleChange = (e, editor) => {
    const data = editor.getData()
    setTaxHealth({ report: data, isValid: data.trim() !== '' ? true : false })
  }

  const handleAssesementSubmit = e => {
    e.preventDefault()
    const editorText = taxHealth.report

    const assessment = {
      report: editorText
    }
    scoreTaxHealth({
      variables: {
        id: id,
        input: assessment
      }
    })
  }
  if (scoreTaxLoading) {
    pageLoading = true
  }
  if (scoreTaxError) {
    console.log(scoreTaxError)
  }
  if (scoreTaxData) {
    // console.log(scoreTaxData)
  }

  const viewAssessment = () => {
    const hashElement = document.getElementById('assessment').offsetTop
    animateScroll.scrollTo(hashElement, {
      duration: 500,
      delay: 0,
      smooth: true,
      containerId: 'dashboard'
    })
  }
  // console.log(review_tax_health)
  return (
    <div>
      {review_tax_health === null ? null : (
        <>
          <Breadcrumb name="Tax health request" />

          <div className="profile-box">
            <div className="profile">
              <div className="profile-intro">
                <div className="desc big">
                  <div className="label">Business name</div>
                  <div className="value">{review_tax_health.business.name}</div>
                </div>
                <div className="desc wide-mob">
                  <div className="label">Yearly turnover</div>
                  <div className="value">
                    <span className="naira">&#8358;</span>
                    {review_tax_health.business.turnover}
                  </div>
                </div>
                <div className="desc">
                  <div className="label">No. of employees</div>
                  <div className="value">
                    {review_tax_health.business.employees}
                  </div>
                </div>
                <div className="desc">
                  <div className="label">Business type</div>
                  <div className="value">{review_tax_health.business.type}</div>
                </div>
                <div className="desc">
                  <div className="label">Tax type</div>
                  <div className="value">{review_tax_health.type}</div>
                </div>
                {review_tax_health.report === null ? (
                  <>
                    <div className="profile-flag red">Unassessed</div>
                    <div className="profile-links">
                      {/* <Link to={`/tax-check/${id}/tax-health`} className="profile-link b purple">
                        Assess tax
                      </Link> */}
                      <button
                        className="profile-link b purple"
                        onClick={viewAssessment}
                      >
                        Assess tax
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="profile-flag green">Assessed</div>
                    <div className="profile-links">
                      {/* <Link to={`/tax-check/${id}/tax-health`} className="profile-link b">
                        View tax report
                      </Link> */}
                      <button
                        className="profile-link b"
                        onClick={viewAssessment}
                      >
                        View tax report
                      </button>
                    </div>
                  </>
                )}
              </div>

              <div className="profile-section">
                <h1 className="title">Health questions</h1>

                <div className="profile-text">
                  {questions.map(quest => {
                    return (
                      <div className="profile-assessment" key={quest.question}>
                        <div className="question">{quest.question}</div>
                        <div className="answer">{quest.answer}</div>
                      </div>
                    )
                  })}
                </div>
              </div>

              <div className="profile-section" id="assessment">
                <h1 className="title">Tax health assessment</h1>

                <form
                  className="profile-text form-data"
                  onSubmit={handleAssesementSubmit}
                >
                  {!review_tax_health.report ? (
                    <>
                      {/* <div className="desc">
                        <div className="label">Score</div>
                        <div className="value score">{totalScore}%</div>
                           <input
                          type="number"
                          min="0"
                          max="100"
                          step="1"
                          placeholder="Input a different score to use"
                          onChange={handleNewChange}
                     /> 
                  </div> */}
                      <div className="desc">
                        <div className="label">Score</div>
                        <div className="value score">
                          {review_tax_health.score}%
                        </div>
                      </div>

                      <div className="desc big">
                        <div className="label">Report</div>

                        <div>
                          <CKEditor
                            editor={ClassicEditor}
                            onChange={handleChange}
                          />
                          <button
                            className="form-btn report"
                            disabled={!taxHealth.isValid}
                          >
                            Submit assessment
                          </button>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="desc">
                        <div className="label">Score</div>
                        <div className="value score">
                          {review_tax_health.score}%
                        </div>
                      </div>
                      <div className="desc big">
                        <div className="label">Report</div>
                        <div className="value report">
                          {ReactHtmlParser(review_tax_health.report)}
                        </div>
                      </div>
                    </>
                  )}
                </form>
              </div>
            </div>
          </div>
        </>
      )}
      {pageLoading ? <PageLoading ripple={true} /> : null}
    </div>
  )
}
export default SingleRequestDetails
