import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import PageLoading from '../../Components/PageLoading/PageLoading'
const LOGIN_ADMIN = gql`
  mutation LoginAdmin($input: AdminLoginInput!) {
    admin_login(input: $input) {
      token_type
      access_token
      expires_in
      error
    }
  }
`

const Login = props => {
  const [login, setLogin] = useState({
    email: '',
    password: ''
  })

  let pageLoading = false
  let authDetailsError = false
  // let loginData = {}
  const [loginAdmin, { loading, data: adminData, error }] = useMutation(
    LOGIN_ADMIN,
    {
      onCompleted: adminData => {
        pageLoading = false
        // console.log(adminData)

        if (!adminData.admin_login.error) {
          localStorage.setItem(
            'token',
            JSON.stringify(adminData.admin_login.access_token)
          )

          props.history.go(`/`)
        }
      }
    }
  )

  const handleChange = e => {
    setLogin({
      ...login,
      [e.target.name]: e.target.value
    })
  }
  const handleFormSubmit = e => {
    e.preventDefault()
    const credentials = login
    if (credentials.email && credentials.password) {
      loginAdmin({ variables: { input: credentials } })
    }
  }

  if (loading) {
    pageLoading = true
  }
  if (error) {
    console.log({ error }[0])
    pageLoading = false
  }
  if (adminData) {
    // console.log(adminData)
    if (adminData.admin_login.error) {
      authDetailsError = true
    }
  }
  return (
    <>
      <form onSubmit={handleFormSubmit}>
        <h1>Login</h1>
        {authDetailsError ? (
          <div className="auth-error">
            <b>OPPS!</b> Something isn't right. Double check your details and
            try again.
            <br />
          </div>
        ) : null}

        <label>Email</label>
        <input
          name="email"
          type="email"
          value={login.email}
          onChange={handleChange}
          // onBlur={handleChange}
          required
        />
        <label>Password</label>
        <input
          name="password"
          type="password"
          value={login.password}
          onChange={handleChange}
          // onBlur={handleChange}
          required
        />

        <div className="submit-box">
          <button type="submit">Login</button>{' '}
          <Link to="/auth/forgot-password" className="forgot">
            Forgot password?
          </Link>
        </div>
      </form>
      {pageLoading ? <PageLoading ripple={true} /> : null}
    </>
  )
}

export default Login
