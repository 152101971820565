import React, { useState } from 'react'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import CKEditor from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import Breadcrumb from '../../../../Components/Breadcrumb/index'
import PageLoading from '../../../../Components/PageLoading/PageLoading'
const TAX_COMPUTATION = gql`
  mutation taxComputationInput($id: Int!, $input: DoTcfInput!) {
    do_tcf(id: $id, input: $input) {
      id
      type
      text_info
      documents
      amount
      report
      status
      created_at
      updated_at
      business {
        id
        name
        employees
      }
    }
  }
`
const ComputationReport = props => {
  const {
    match: {
      params: { id }
    }
  } = props
  const [taxComputation, setTaxComputation] = useState({
    report: '',
    amount: ''
  })
  const [taxComputations, { loading, error, data }] = useMutation(
    TAX_COMPUTATION
  )
  let reportSent = false
  let pageLoading = false
  const [value, setValue] = useState('')
  const handleChange = (e, editor) => {
    const data = editor.getData()

    setValue(data)
  }
  const handleNewChange = e => {
    const { value } = e.target
    setTaxComputation({ amount: value })
  }
  const handleAssesementSubmit = e => {
    e.preventDefault()
    const editorText = value.replace(/(<([^>]+)>)/gi, '')
    taxComputation.report = editorText
    // console.log(taxComputation)
    taxComputations({
      variables: {
        id: id,
        input: taxComputation
      }
    })
  }
  if (loading) {
    pageLoading = true
  }
  if (error) {
    console.log(error)
  }
  if (data) {
    reportSent = true
  }
  return (
    <div>
      <Breadcrumb name="Computation" />
      {!reportSent ? (
        <form onSubmit={handleAssesementSubmit} className="form-data">
          <label>Amount</label>
          <input type="text" onChange={handleNewChange} required />
          <label>Report</label>
          <CKEditor editor={ClassicEditor} onChange={handleChange} />
          <button className="form-btn report">Submit</button>
        </form>
      ) : (
        <div className="view">
          <div className="view-inner">
            <p>Report Sent</p>
          </div>
        </div>
      )}
      {pageLoading ? <PageLoading ripple={true} /> : null}
    </div>
  )
}

export default ComputationReport
