import React from 'react'
import './index.css'
import logo from '../../assets/images/logo/logo.svg'
import gql from 'graphql-tag'
import { useMutation, useQuery } from '@apollo/react-hooks'
import PageLoading from '../PageLoading/PageLoading'
import { Link } from 'react-router-dom'

const LOG_OUT_MUTATION = gql`
  mutation {
    admin_logout
  }
`

const IS_LOGGED_IN = gql`
  query IsUserLoggedIn {
    token @client
    user @client
    role @client
  }
`
const Header = props => {
  const { data, loading: tokenLoading } = useQuery(IS_LOGGED_IN) || null
  let pageLoading = false
  const [logOutAdmin, { loading, error }] = useMutation(LOG_OUT_MUTATION, {
    onCompleted: statusLogOut => {
      localStorage.removeItem('token')
      window.location.reload(true)
    }
  })

  if (tokenLoading) {
    pageLoading = true
  }
  if (loading) {
    pageLoading = true
  }
  if (error) {
    return <p>You have an error</p>
  }

  const logOut = () => {
    logOutAdmin()
  }
  return (
    <div className="header">
      <div className="header-logo">
        <Link to="/">
          <img src={logo} alt="logo" />
        </Link>
      </div>
      <div className="sess-menu">
        {data && data.token ? (
          <div className="sess-user">
            <span className="lynk a">
              {data.user} <span className="role">({data.role})</span>
              <i className="la la-user-circle"></i>
            </span>
            <span className="lynk b" onClick={logOut}>
              Logout
            </span>
          </div>
        ) : null}
      </div>

      {pageLoading ? <PageLoading ripple={true} /> : null}
    </div>
  )
}

export default Header
