import React, { useState } from 'react'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import Breadcrumb from '../../../Components/Breadcrumb'
import PageLoading from '../../../Components/PageLoading/PageLoading'
import { Link } from 'react-router-dom'
const NEW_CONSULTANT = gql`
  mutation NewConsultant($input: registerConsultantInput!) {
    register_consultant(input: $input) {
      id
      first_name
      last_name
      email
      qualification
    }
  }
`

const ALL_CONSULTANTS = gql`
  query all_consultants($first: Int!, $page: Int!) {
    consultants(first: $first, page: $page) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
      data {
        id
        first_name
        last_name
        email
        created_at
        updated_at
        assignments
        qualification
        roles {
          name
        }
      }
    }
  }
`

const NewConsultant = props => {
  const [newConsultant, setNewConsultant] = useState({
    first_name: '',
    last_name: '',
    email: '',
    tier: 1,
    qualification: '',
    isValid: false
  })
  const [currentPage] = useState(1)
  const [registerConsultants, { loading, error, data }] = useMutation(
    NEW_CONSULTANT,
    {
      refetchQueries: [
        { query: ALL_CONSULTANTS, variables: { first: 10, page: currentPage } }
      ]
    }
  )
  let pageLoading = false
  let isConsultant = false

  const checkValidity = () => {
    let valid = true
    valid = newConsultant.first_name.trim() !== '' && valid
    valid = newConsultant.last_name.trim() !== '' && valid
    valid = newConsultant.email.trim() !== '' && valid
    // valid = newConsultant.tier.trim() !== '' && valid
    valid = newConsultant.qualification.trim() !== '' && valid
    return valid
  }
  const handleChange = e => {
    const target = e.target
    const name = target.name
    setNewConsultant({
      ...newConsultant,
      [name]: target.value,
      isValid: checkValidity()
    })
  }
  if (loading) {
    pageLoading = true
  }

  if (error) {
    return (
      <div className="dashform-box">
        <div className="dashform">
          <div className="dashform-intro text-center">
            Well, this is embarrassing. Something went wrong. Please try again later.
          </div>
        </div>
      </div>
    )
  }
  if (data) {
    isConsultant = true
  }
  const handleSubmit = e => {
    e.preventDefault()

    const consultantDetails = {
      first_name: newConsultant.first_name,
      last_name: newConsultant.last_name,
      email: newConsultant.email,
      tier: Number(newConsultant.tier),
      qualification: newConsultant.qualification
    }
    registerConsultants({
      variables: {
        input: consultantDetails
      }
    })
    // props.history.push('/consultants')
    // console.log(consultantDetails)
  }
  // console.log(newConsultant)
  return (
    <div>
      <Breadcrumb name="New Consultant" />
      {!isConsultant ? (
        <form onSubmit={handleSubmit} className="dashform-box w-50">
          <div className="dashform update-profile">
            <div className="dashform-intro">Create Consultant</div>
            <label>Firstname</label>
            <input
              type="text"
              value={newConsultant.first_name}
              onChange={handleChange}
              required
              name="first_name"
            />
            <p className="error"></p>
            <label>Lastname</label>
            <input
              type="text"
              value={newConsultant.last_name}
              required
              onChange={handleChange}
              name="last_name"
            />
            <p className="error"></p>
            <label className="mail">Email</label>
            <input
              type="email"
              value={newConsultant.email}
              required
              onChange={handleChange}
              name="email"
            />
            <label className="mails">Qualification</label>
            <input
              type="text"
              value={newConsultant.qualification}
              required
              onChange={handleChange}
              name="qualification"
            />
            <p className="error"></p>
            <label className="select">
              Tier
              <select
                className="selector"
                onChange={handleChange}
                onBlur={handleChange}
                value={newConsultant.tier}
                name="tier"
              >
                <option value={1} key={1}>
                  {' '}
                  1
                </option>
                <option value={2} key={2}>
                  {' '}
                  2
                </option>
                <option value={3} key={3}>
                  {' '}
                  3
                </option>
              </select>
            </label>

            <div className="submit-box">
              <button type="submit" disabled={!newConsultant.isValid}>
                Submit
              </button>
            </div>
          </div>
        </form>
      ) : (
        <div className="dashform-box">
          <div className="dashform">
            <div className="dashform-intro text-center">
              Consultant created successfully
            </div>
            <div className="dashform-message">
              Go back to
              <Link to="/Consultants" className="link a">
                Consultants
              </Link>
            </div>
          </div>
        </div>
      )}
      {pageLoading ? <PageLoading ripple={true} /> : null}
    </div>
  )
}

export default NewConsultant
