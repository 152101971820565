import React from 'react'
// eslint-disable-next-line
import {
  Switch,
  Route,
  Redirect,
  useLocation
  // useRouteMatch
} from 'react-router-dom'
// import { BrowserRouter } from 'react-router-dom';
import './App.css'
import Header from './Components/Header/index'
import Main from './Pages'
import AuthPages from './AuthPages'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import NewPassword from './Pages/SuperAdmin/Password/PasswordChange'

const RestrictedRoute = ({ component: Component, token, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      token ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={
            '/auth'
            // state: {from: props.location}
          }
        />
      )
    }
  />
)

const IS_LOGGED_IN = gql`
  query IsUserLoggedIn {
    token @client
  }
`

const App = props => {
  const { data } = useQuery(IS_LOGGED_IN) || null
  const location = useLocation()
  // const history = useHistory();
  // const match = useRouteMatch('/')
  // const initURL = history.location.pathname

  if (location.pathname === '/auth') {
    if (data.token !== null) {
      return <Redirect to={'/'} />
    }
  }

  return (
        <div className="App">
          <Header />
          <Switch>
            <Route path="/auth" component={AuthPages} />
            <Route
              exact
              path="/password/reset/:token"
              render={props => <NewPassword {...props} />}
            />
            <RestrictedRoute
              // path={`${match.url}`}
              path="/"
              token={data.token}
              component={Main}
            />
          </Switch>
        </div>
  )
}

export default App
