import React, { useState } from 'react'
import Breadcrumb from '../../../../Components/Breadcrumb/index'
import Moment from 'react-moment'
import PageLoading from '../../../../Components/PageLoading/PageLoading'
import '../Business.css'
// import { Link } from 'react-router-dom'
import { animateScroll } from 'react-scroll'
import CKEditor from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import gql from 'graphql-tag'
import { useQuery, useMutation } from '@apollo/react-hooks'
import ReactHtmlParser from 'react-html-parser'

const REVIEW_TAX_CF = gql`
  query review_TAX_CF($id: Int!) {
    review_tcf(id: $id) {
      id
      type
      text_info
      documents
      report
      status
      period
      amount
      created_at
      updated_at
      business {
        id
        user_id
        name
        slug
        type
        employees
        turnover
        designation

        rc_no
        bn_no
        tin
        vat_reg_no
        address
        tier
        verified
        created_at
        updated_at
        cac_docs {
          files
        }
      }
    }
  }
`
const TAX_COMPUTATION = gql`
  mutation taxComputationInput($id: Int!, $input: DoTcfInput!) {
    do_tcf(id: $id, input: $input) {
      id
      type
      text_info
      documents
      amount
      report
      status
      amount
      created_at
      updated_at
      business {
        id
        name
        employees
      }
    }
  }
`
const SingleComputation = props => {
  const {
    match: {
      params: { id }
    }
  } = props
  const [taxComputation, setTaxComputation] = useState({
    report: '',
    amount: '',
    isValid: false
  })

  let pageLoading = false

  const [
    taxComputations,
    { loading: computeLoading, error: computeError, data: computeData }
  ] = useMutation(TAX_COMPUTATION)
  const { data, loading, error } = useQuery(REVIEW_TAX_CF, {
    variables: { id: id }
  })
  if (loading) {
    return <PageLoading ripple={true} />
  }
  if (error) {
    return <p>Error</p>
  }

  const { review_tcf } = data
  let computation = {}
  let documents = []
  let assessment = []
  if (data && review_tcf) {
    computation = review_tcf
    const docs = computation.documents ? JSON.parse(computation.documents) : []
    for (const key in docs) {
      documents.push({
        name: key,
        path: docs[key]
      })
    }
    if (computation.text_info !== 'null') {
      const questions = JSON.parse(review_tcf.text_info)
      questions.forEach(quest => {
        for (const key in JSON.parse(quest)) {
          assessment.push({
            question: key,
            answer: JSON.parse(quest)[key]
          })
        }
      })
    }
  }

  // let reportSent = false
  const handleChange = (e, editor) => {
    const data = editor.getData()
    setTaxComputation({
      ...taxComputation,
      report: data,
      isValid: checkValidity()
    })
  }
  const handleNewChange = e => {
    const amnt = e.target.value
    setTaxComputation({
      ...taxComputation,
      amount: amnt,
      isValid: checkValidity(amnt)
    })
  }
  const checkValidity = () => {
    let valid = true
    valid = taxComputation.amount.trim() !== '' && valid
    valid = taxComputation.report.trim() !== '' && valid
    return valid
  }
  const handleAssesementSubmit = e => {
    e.preventDefault()
    const editorText = taxComputation.report
    const taxCompute = { amount: taxComputation.amount, report: editorText }

    taxComputations({
      variables: {
        id: id,
        input: taxCompute
      }
    })
  }

  if (computeLoading) {
    pageLoading = true
  }
  if (computeError) {
    console.log(computeError)
  }
  if (computeData) {
    // reportSent = true
    // console.log(computeData)
  }

  const viewAssessment = () => {
    const hashElement = document.getElementById('assessment').offsetTop
    animateScroll.scrollTo(hashElement, {
      duration: 500,
      delay: 0,
      smooth: true,
      containerId: 'dashboard'
    })
  }
  return (
    <div>
      <Breadcrumb name="Tax computation" />

      <div className="profile-box">
        <div className="profile">
          <div className="profile-intro">
            <div className="desc big">
              <div className="label">Business name</div>
              <div className="value">{review_tcf.business.name}</div>
            </div>
            <div className="desc wide-mob">
              <div className="label">Yearly turnover</div>
              <div className="value">
                <span className="naira">&#8358;</span>
                {review_tcf.business.turnover}
              </div>
            </div>
            <div className="desc">
              <div className="label">No. of employees</div>
              <div className="value">{review_tcf.business.employees}</div>
            </div>
            <div className="desc">
              <div className="label">Business type</div>
              <div className="value">{review_tcf.business.type}</div>
            </div>

            <div className="desc wide-mob">
              <div className="label">Period</div>
              <div className="value">
                <Moment format="D MMM YYYY">{review_tcf.period}</Moment>
              </div>
            </div>
            {!review_tcf.amount ? (
              <>
                <div className="profile-flag red">Not computed</div>
                <div className="profile-links">
                  {/* <Link to={`/tax-check/${id}/tax-health`} className="profile-link b purple">
                    Assess tax
                  </Link> */}
                  <button
                    className="profile-link b purple"
                    onClick={viewAssessment}
                  >
                    Compute tax
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="profile-flag green">Computed</div>
                <div className="profile-links">
                  {/* <Link
                    to={`/tax-check/${id}/tax-health`}
                    className="profile-link b"
                  >
                    View computation
                </Link> */}
                  <button className="profile-link b" onClick={viewAssessment}>
                    View tax report
                  </button>
                </div>
              </>
            )}
          </div>

          {assessment.length > 0 ? (
            <div className="profile-section">
              <h1 className="title">Tax assessment</h1>

              <div className="profile-text">
                {assessment.map(questItem => {
                  return (
                    <div
                      className="profile-assessment"
                      key={questItem.question}
                    >
                      <div className="question">{questItem.question}</div>
                      <div className="answer">{questItem.answer}</div>
                    </div>
                  )
                })}
              </div>
            </div>
          ) : null}

          {documents.length > 0 ? (
            <div className="profile-section">
              <h1 className="title">Tax documents</h1>

              <div className="profile-text">
                {documents.map(item => {
                  return (
                    <div className="profile-assessment" key={item.name}>
                      <div className="question">{item.name}</div>
                      <div className="answer">
                        <a
                          target="_blank"
                          href={item.path}
                          rel="noopener noreferrer"
                        >
                          Download <i className="la la-download"></i>
                        </a>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          ) : null}

          <div className="profile-section" id="assessment">
            <h1 className="title">Tax computation</h1>

            <form
              className="profile-text form-data"
              onSubmit={handleAssesementSubmit}
            >
              {!review_tcf.amount ? (
                <>
                  <div className="desc">
                    <div className="label">
                      Amount(<span className="naira bold">&#8358;</span>)
                    </div>
                    <input
                      type="number"
                      min="0"
                      step="0.1"
                      onChange={handleNewChange}
                      required
                    />
                  </div>

                  <div className="desc big">
                    <div className="label">Report</div>

                    <div>
                      <CKEditor
                        editor={ClassicEditor}
                        onChange={handleChange}
                      />
                      <button
                        className="form-btn report"
                        disabled={!taxComputation.isValid}
                      >
                        Submit computation
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="desc">
                    <div className="label">
                      Amount(<span className="naira bold">&#8358;</span>)
                    </div>
                    <div className="value score">
                      <span className="naira">&#8358;</span>
                      {review_tcf.amount}
                    </div>
                  </div>
                  <div className="desc big">
                    <div className="label">Report</div>
                    <div className="value report">
                      {ReactHtmlParser(review_tcf.report)}
                    </div>
                  </div>
                </>
              )}
            </form>
          </div>
        </div>
      </div>
      {pageLoading ? <PageLoading ripple={true} /> : null}
    </div>
  )
}

export default SingleComputation
