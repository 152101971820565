import React from 'react'
import './index.css'
import { Link } from 'react-router-dom'

const card = props => {
  return (
    <div className={`card-box ${props.w} `}>
      <Link to={props.link} className={`card ${props.h} ${props.align} `}>
        {props.button ? <>
          <div className={`card-button ${props.theme}`}>
            {props.button}
          </div>
        </> 
        : <>
          {props.icon ? 
            <div className="card-icon">
              <i className={`la la-${props.icon} ${props.theme}`}></i>
            </div> : null
          }
          {props.header1 ?
            <h1 className="card-header1">{props.header1}</h1> : null
          }
          {props.header2 ? 
            <h1 className="card-header2">{props.header2}{props.header2min ? <span>/{props.header2min}</span> : null}</h1> : null
          }         
          {props.header3 ?
            <h1 className={`card-header3 ${props.theme}`}>{props.header3}{props.header3min ? <span>/{props.header3min}</span> : null}</h1> : null
          }            
          {props.header0 ?
            <h4 className="card-header0">{props.header0}</h4> : null
          }
          {/* {props.link ? (
            <Link to={props.link} className={`card-link ${props.theme}`}>
              View
            </Link>
          ) : null} */}
        </>}
      </Link>
    </div>
  )
}

export default card
