import React, { useState, useEffect } from 'react'
import gql from 'graphql-tag'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { Link } from 'react-router-dom'
import PageLoading from '../../../Components/PageLoading/PageLoading'
import Breadcrumb from '../../../Components/Breadcrumb'

const ONE_CONSULTANT = gql`
  query one_consultant($id: Int!) {
    consultant(id: $id) {
      email
    }
  }
`

const EDIT_CONSULTANT = gql`
  mutation EditConsultant($input: editConsultantInput!) {
    edit_consultant(input: $input) {
      id
      first_name
      last_name
      email
      created_at
      qualification
      roles {
        name
      }
    }
  }
`
// const ALL_CONSULTANTS = gql`
//   query all_consultants($first: Int!, $page: Int!) {
//     consultants(first: $first, page: $page) {
//       paginatorInfo {
//         count
//         currentPage
//         firstItem
//         hasMorePages
//         lastItem
//         lastPage
//         perPage
//         total
//       }
//       data {
//         id
//         first_name
//         last_name
//         email
//         created_at
//         updated_at
//         assignments
//         qualification
//         roles {
//           name
//         }
//       }
//     }
//   }
// `

let isEdited = false

const EditConsultant = props => {
  const {
    match: {
      params: { id }
    }
  } = props

  let pageLoading = false
  let consultantId = parseInt(id)
  
  const {
    data: consultantData,
    loading: consultantLoading,
    error: consultantError
  } = useQuery(ONE_CONSULTANT, {
    variables: {
      id: consultantId
    }
  })
  
  const [editConsultant, setEditConsultant] = useState({
    email: '',
    tier: ''
  })

  if (consultantLoading) {
    pageLoading = true
  }

  useEffect(() => {
    if (consultantData) {
      let { consultant } = consultantData
      setEditConsultant({
        email: consultant.email,
        tier: 1
      })
    }
  }, [consultantData])

  const [currentPage] = useState(1)

  const [editConsultants, { loading, error, data }] = useMutation(
    EDIT_CONSULTANT
    // {
    //   refetchQueries: [
    //     { query: ALL_CONSULTANTS, variables: { first: 10, page: currentPage } }
    //   ]
    // }
  )

  if (loading) {
    pageLoading = true
  }

  if (error) {
    return (
      <div className="dashform-box">
        <div className="dashform">
          <div className="dashform-intro text-center">
            Well, this is embarrassing. Something went wrong. Please try again later.
          </div>
        </div>
      </div>
    )
  }

  const handleChange = e => {
    const target = e.target
    const name = target.name
    setEditConsultant({
      ...editConsultant,
      [name]: target.value
    })
  }

  const handleSubmit = e => {
    e.preventDefault()

    const consultantDetails = {
      email: editConsultant.email,
      tier: Number(editConsultant.tier)
    }

    editConsultants({
      variables: {
        input: consultantDetails
      }
    })

    if (data) {
      isEdited = true
    }
  }

  return (
    <div>
      <Breadcrumb name="Upgrade Consultant" />

      {!isEdited ? (
        <form onSubmit={handleSubmit} className="dashform-box w-50">
          <div className="dashform update-profile">
            <div className="dashform-intro">Upgrade Consultant</div>

            <label className="mail">Email</label>
            <input
              type="email"
              value={editConsultant.email}
              required
              readOnly
              onChange={handleChange}
              name="email"
            />

            <label className="select">
              Tier
              <select
                className="selector"
                onChange={handleChange}
                onBlur={handleChange}
                value={editConsultant.tier}
                name="tier"
              >
                <option value={1} key={1}>
                  {' '}
                  1
                </option>
                <option value={2} key={2}>
                  {' '}
                  2
                </option>
                <option value={3} key={3}>
                  {' '}
                  3
                </option>
              </select>
            </label>

            <div className="submit-box">
              <button type="submit" disabled={consultantLoading}>
                Submit
              </button>
            </div>
          </div>
        </form>
      ) : (
        <div className="dashform-box">
          <div className="dashform">
            <div className="dashform-intro text-center">
              Consultant upgraded successfully
            </div>

            <div className="dashform-message">
              Go back to
              <Link to="/consultants" className="link a">
                Consultants
              </Link>
            </div>
          </div>
        </div>
      )}

      {pageLoading ? <PageLoading ripple={true} /> : null}
    </div>
  )
}

export default EditConsultant
