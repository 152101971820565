import React, { useState } from 'react'

import gql from 'graphql-tag'
import { useQuery, useMutation } from '@apollo/react-hooks'
import PageLoading from '../../../Components/PageLoading/PageLoading'

const SINGLE_PLAN = gql`
  query singlePlan($id: Int!) {
    plan(id: $id) {
      id
      name
      plan_code
      description
      amount
      interval
      currency
      created_at
      updated_at
    }
  }
`
const UPDATE_PLAN = gql`
  mutation CreatePlan($input: createOrUpdatePlanInput!) {
    create_plan(input: $input) {
      id
      name
      plan_code
      description
      amount
      interval
      currency
      created_at
      updated_at
    }
  }
`
const EditPlan = props => {
  const {
    match: {
      params: { id }
    }
  } = props

  let planId = parseInt(id)

  const { data: planData, loading, error } = useQuery(SINGLE_PLAN, {
    variables: {
      id: planId
    }
  })

  let pageLoading = false
  let plan = {}
  
  if (loading) {
    pageLoading = true
  }
  
  if (error) {
    console.log(error)
  }
  
  if (planData) {
    plan = planData.plan
  }

  const [editPlan, setEditPlan] = useState({
    id: planId,
    name: plan.name,
    description: plan.description,
    amount: plan.amount,
    interval: plan.interval,
  })

  const [
    updatePlan,
    { data: updateData, loading: updateLoading, error: updateError }
  ] = useMutation(UPDATE_PLAN, {
    refetchQueries: [{ query: SINGLE_PLAN, variables: { id: planId } }]
  })

  if (updateLoading) {
    pageLoading = true
  }

  if (updateError) {
    console.log(error)
  }

  const handlePlanChange = e => {
    setEditPlan({
      ...editPlan,
      [e.target.name]: e.target.value
    })
  }

  const handleSubmit = e => {
    e.preventDefault()
    if (editPlan.amount < 100) {
      return
    }

    const updatedDetails = {
      id: editPlan.id,
      name: editPlan.name,
      description: editPlan.description,
      amount: editPlan.amount,
      interval: editPlan.interval,
    }

    updatePlan({
      variables: {
        input: updatedDetails
      }
    })

    props.history.push(`/plans/${id}`)
  }

  if (updateData) {
    console.log(updateData)
  }

  return (
    <div>
      <form className="dashform-box w-50" onSubmit={handleSubmit}>
        <div className="dashform update-profile">
          <div className="dashform-intro">Edit Plan</div>
          <label>Name</label>
          <input
            type="text"
            value={plan.name}
            onChange={handlePlanChange}
            required
            name="name"
            readOnly={true}
          />

          <label>Description</label>
          <textarea
            value={plan.description}
            onChange={handlePlanChange}
            required
            name="description"
            readOnly={true}
          ></textarea>

          <label>
            Amount<span className="naira bold">(&#8358;)</span>
          </label>
          <input
            type="number"
            value={editPlan.amount}
            onChange={handlePlanChange}
            required
            name="amount"
          />

          <label>
            Interval
            <select
              className="selector"
              onChange={handlePlanChange}
              onBlur={handlePlanChange}
              value={plan.interval}
              name="interval"
              required
              disabled
            >
              <option value={plan.interval}> {plan.interval}</option>
            </select>
          </label>
          
          <div className="submit-box">
            <button
              type="submit"
              //  disabled={!plan.isValid}
            >
              Submit
            </button>
          </div>
        </div>
      </form>
      {pageLoading ? <PageLoading ripple={true} /> : null}
    </div>
  )
}

export default EditPlan
