import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Breadcrumb from '../../../../Components/Breadcrumb/index'
import gql from 'graphql-tag'
import { useQuery, useMutation } from '@apollo/react-hooks'
import './new.css'
import PageLoading from '../../../../Components/PageLoading/PageLoading'

const ONE_CONSULTANT = gql`
  query one_consultant($id: Int!) {
    consultant(id: $id) {
      id
      first_name
      last_name
      created_at
      email
      qualification
      roles {
        name
      }
      assignments
      assigned_businesses {
        name
        slug
      }
    }
  }
`
const SUSPEND_ADMIN = gql`
  mutation SuspendAdmin($email: String!, $suspend: Boolean!) {
    suspend_admin(email: $email, suspend: $suspend) {
      suspended
    }
  }
`
const GET_PROFILE = gql`
  {
    ad {
      id
      first_name
      last_name
      email
      roles {
        name
      }
    }
  }
`

const SingleConsultant = props => {
  const { id } = props.match.params

  let pageLoading = false

  const { loading, error, data } = useQuery(ONE_CONSULTANT, {
    variables: { id: id }
  }
  )

  const { data: dataRole } = useQuery(GET_PROFILE)

  let role

  if (dataRole && dataRole.ad) {
    role = dataRole.ad.roles[0].name
  }

  if (loading) {
    pageLoading = true
  }

  if (error) {
    console.log(error)
  }

  let consultant = {}
  
  if (data) {
    consultant = data.consultant
  }

  const [suspended, setSuspended] = useState(false)

  const [suspendAdmin, { loading: suspendedLoading }] = useMutation(
    SUSPEND_ADMIN
  )

  const handleSuspension = email => {
    setSuspended(!suspended)
    suspendAdmin({
      variables: {
        email: email,
        suspend: !suspended
      }
    })
  }

  if (suspendedLoading) {
    pageLoading = true
  }

  const suspenseColor = () => {
    if (suspended && !suspendedLoading) {
      return 'grey'
    } else if (!(suspended && suspendedLoading)) {
      return 'purple'
    }
  }

  return (
    <div className="customer-box">
      {data ? (
        <>
          <Breadcrumb name="Consultant" />

          <div className="profile-box">
            <div className="profile">
              <div className="profile-intro">
                <div className="desc big">
                  <div className="label">Name</div>
                  <div className="value">
                    {consultant.first_name + ' ' + consultant.last_name}
                  </div>
                </div>

                {role === 'super admin' && (
                  <div className="profile-flag green">
                    <button
                      onClick={() => handleSuspension(consultant.email)}
                      className={`suspense_btn ${suspenseColor()}`}
                    >
                      {suspended && !suspendedLoading ? 'Suspended' : 'Active'}
                    </button>
                  </div>
                )}

                <div className="desc wide">
                  <div className="label">Email</div>
                  <div className="value">{consultant.email}</div>
                </div>

                <div className="desc">
                  <div className="label">Created At</div>
                  <div className="value">
                    {new Date(consultant.created_at).toDateString()}
                  </div>
                </div>
                
                <div className="desc">
                  <div className="label">Role</div>
                  <div className="value">{consultant.roles[0].name}</div>
                </div>
                
                <div className="desc">
                  <div className="label">Assignments</div>
                  <div className="value">{consultant.assignments}</div>
                </div>
                
                <div className="desc">
                  <div className="label">Qualification</div>
                  <div className="value">{consultant.qualification}</div>
                </div>
                
                <div className="profile-links">
                  <Link to={`/consultants/${consultant.id}/edit`}>
                    <button
                      className="profile-link b purple"
                    >
                      Edit
                    </button>
                  </Link>
                </div>
              </div>

              {consultant.assignments > 0 ? (
                <div className="profile-section">
                  <h5 className="title">Assigned Businesses</h5>
                  <div className="profile-text">
                    {consultant.assigned_businesses.map(assignedBusiness => {
                      return (
                        <div
                          key={assignedBusiness.id}
                          className="profile-assessment"
                        >
                          <div className="question">
                            {assignedBusiness.name}
                          </div>
                          <div className="answer">
                            <Link to={`/businesses/${assignedBusiness.slug}`}>
                              View
                            </Link>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              ) : (
                <div className="profile-section">
                  <div className="profile-text">
                    <div className="profile-assessment">
                      No business assigned
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      ) : null}
      {pageLoading ? <PageLoading ripple={true} /> : null}
    </div>
  )
}

export default SingleConsultant
