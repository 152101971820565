import React from 'react'
import Breadcrumb from '../../../Components/Breadcrumb/index'
import Card from '../../../Components/Card/index'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import PageLoading from '../../../Components/PageLoading/PageLoading'

const GET_STATS = gql`
  query {
    admin_stats {
      admins
      consultants
      u_consultants
      businesses
      u_businesses
      nt_businesses
      support
      o_support
    }
  }
`
const AdminHome = () => {
  const { loading, error, data } = useQuery(GET_STATS)
  let pageLoading = false
  if (loading) {
    pageLoading = true
  }
  if (error) {
    return (
      <div className="dashform-box">
        <div className="dashform">
          <div className="dashform-intro text-center">
            Well, this is embarrassing. Something went wrong. Please try again later.
          </div>
        </div>
      </div>
    )
  }
  let stats = {}
  if (data) {
    stats = data.admin_stats
  }

  return (
    <div>
      {pageLoading ? <PageLoading ripple={true} /> : null}
      <Breadcrumb name="Admin-Home" />
      <Card
        w="w-1of3"
        h="h-250"
        align="text-center"
        theme="purple"
        icon="users"
        header2={data ? String(stats.consultants) : ''}
        header2min={
          data
            ? String(
                stats.consultants * 20 - (stats.businesses - stats.u_businesses)
              )
            : ''
        }
        header3="Consultants"
        header3min="business slots"
        header0="Click to view consultants list"
        link="/consultants"
      />

      <Card
        w="w-1of3"
        h="h-250"
        align="text-center"
        theme="purple"
        icon="briefcase"
        header2={data ? String(stats.businesses) : ''}
        header2min={data ? String(stats.u_businesses) : ''}
        header3="Businesses"
        header3min="unverified"
        header0="Click to view businesses list"
        link="/businesses"
      />
      {/* <Card
        w="w-1of3"
        h="h-250"
        align="text-center"
        theme="purple"
        icon="briefcase"
        header2="214"
        header2min=""
        header3="Assignments"
        header3min=""
        header0="Click to view Assignments"
        link="/assignments"
  /> */}
      <Card
        w="w-1of3"
        h="h-250"
        align="text-center"
        theme="purple"
        icon="briefcase"
        header2={data ? String(stats.nt_businesses) : ''}
        header3="Change tier"
        header0="Click to change business tier"
        link="/change-tier"
      />
      <Card
        w="w-1of3"
        h="h-250"
        align="text-center"
        theme="purple"
        icon="comments"
        header2={data ? String(stats.support) : ''}
        header2min={data ? String(stats.o_support) : ''}
        header3="Supports"
        header3min="Open"
        header0="Click to view supports list"
        link="/support"
      />
      <Card
        w="w-1of3"
        h="h-250"
        align="text-center"
        theme="purple"
        icon="file-medical-alt"
        header3="Tax Health Requests"
        header3min="Open"
        header0="Click to view tax health list"
        link="/tax-health-requests"
      />
      <Card
        w="w-1of3"
        h="h-250"
        align="text-center"
        theme="purple"
        icon="calculator"
        header3="Computation Requests"
        header3min="Open"
        header0="Click to view computation list"
        link="/tax-computation-requests"
      />
    </div>
  )
}

export default AdminHome
