import React from 'react'
import gql from 'graphql-tag'

import { useQuery } from '@apollo/react-hooks'
const ALL_ASSIGNMENTS = gql`
  {
    all_assignments {
      first_name
      last_name
      email
      created_at
      updated_at
      roles {
        name
      }
      assigned_businesses {
        id
        name
        employees
        tin
        type
        tier
        turnover
        rc_no
      }
      assignments
    }
  }
`

const Assignments = () => {
  const { loading, error, data } = useQuery(ALL_ASSIGNMENTS)
  if (loading) {
    return <p>Loading...</p>
  }
  if (error) {
    console.log(error)
  }
  // console.log(data)
  const { all_assignments } = data
  return (
    <div className="customer-box">
      {all_assignments.length > 0 ? (
        <div className="box">
          <>
            {all_assignments.map((assignment, index) => {
              return (
                <div className="single-con" key={index}>
                  <div className="con-box" key={index}>
                    <span className="head">Firstname</span>
                    <span className="body">{assignment.first_name}</span>
                  </div>
                  <div className="con-box">
                    <span className="head">Lastname</span>
                    <span className="body">{assignment.last_name}</span>
                  </div>
                  <div className="con-box">
                    <span className="head">Role</span>
                    <span className="body">{assignment.roles[0].name}</span>
                  </div>
                  <div className="con-box">
                    <span className="head">Number of Assignments</span>
                    <span className="body">{assignment.assignments}</span>
                  </div>

                  {assignment.assigned_businesses.map(business => {
                    return (
                      <div className="uploads" key={business.id}>
                        <div className="con-box">
                          <span className="head">Business Name</span>
                          <span className="body">{business.name}</span>
                        </div>

                        <div className="con-box">
                          <span className="head">Business Tin</span>
                          <span className="body">{business.tin}</span>
                        </div>

                        <div className="con-box">
                          <span className="head">Business Type</span>
                          <span className="body">{business.type}</span>
                        </div>
                        <div className="con-box">
                          <span className="head">Business Tier</span>
                          <span className="body">{business.tier}</span>
                        </div>
                      </div>
                    )
                  })}
                </div>
              )
            })}
          </>
        </div>
      ) : (
        <p>No Assignment</p>
      )}
    </div>
  )
}
export default Assignments
