import React, { useState } from 'react'
import gql from 'graphql-tag'
import { useQuery, useMutation } from '@apollo/react-hooks'
import Breadcrumb from '../../../Components/Breadcrumb/index'
import PageLoading from '../../../Components/PageLoading/PageLoading'
const SINGLE_ADMIN = gql`
  query one_Admin($id: Int!) {
    admin(id: $id) {
      id
      first_name
      last_name
      email
      created_at
      updated_at
      roles {
        name
      }
    }
  }
`

const SUSPEND_ADMIN = gql`
  mutation SuspendAdmin($email: String!, $suspend: Boolean!) {
    suspend_admin(email: $email, suspend: $suspend) {
      suspended
    }
  }
`
const SingleAdmin = props => {
  // console.log(props.match)
  const { id } = props.match.params
  let pageLoading = false
  const { loading, error, data } = useQuery(SINGLE_ADMIN, {
    variables: { id: id }
  })
  const [suspended, setSuspended] = useState(false)

  const [suspendAdmin, { suspendedLoading }] = useMutation(SUSPEND_ADMIN)

  const handleSuspension = email => {
    setSuspended(!suspended)
    suspendAdmin({
      variables: {
        email: email,
        suspend: !suspended
      }
    })
  }

  if (suspendedLoading) {
    pageLoading = true
  }

  const suspenseColor = () => {
    if (suspended && !suspendedLoading) {
      console.log('Hrrryyyy')
      return 'grey'
    } else if (!(suspended && suspendedLoading)) {
      return 'purple'
    }
  }

  if (loading) {
    pageLoading = true
  }

  if (error) {
    return (
      <div className="dashform-box">
        <div className="dashform">
          <div className="dashform-intro text-center">
            Well, this is embarrassing. Something went wrong. Please try again later.
          </div>
        </div>
      </div>
    )
  }
  let admin = {}
  if (data) {
    admin = data.admin
  }

  return (
    <div className="customer-box">
      {data ? (
        <>
          <Breadcrumb name="Admin" />
          <div className="profile-box">
            <div className="profile">
              <div className="profile-intro">
                <div className="desc big">
                  <div className="label">Name</div>
                  <div className="value">
                    {admin.first_name + ' ' + admin.last_name}
                  </div>
                </div>

                <div className="profile-flag green">
                  <button
                    onClick={() => handleSuspension(admin.email)}
                    className={`suspense_btn ${suspenseColor()}`}
                  >
                    {suspended && !suspendedLoading ? 'Suspended' : 'Active'}
                  </button>
                </div>

                <div className="desc">
                  <div className="label">Email</div>
                  <div className="value">{admin.email}</div>
                </div>
                <div className="desc">
                  <div className="label">Created_at</div>
                  <div className="value">
                    {new Date(admin.created_at).toDateString()}
                  </div>
                </div>
                <div className="desc">
                  <div className="label">Role</div>
                  <div className="value">{admin.roles[0].name}</div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
      {pageLoading ? <PageLoading ripple={true} /> : null}
    </div>
  )
}

export default SingleAdmin
