import React from 'react'
import './PageNotFound.css'
import { Link } from 'react-router-dom'

const PageNotFound = () => {
  return (
    <div className="page-not-found">
      <h1>404</h1>
      <h4>The page you are looking for cannot be found.</h4>
      <Link to="/" className="link">
        Home
      </Link>
    </div>
  )
}

export default PageNotFound
