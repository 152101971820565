import React from 'react'
import Breadcrumb from '../../../Components/Breadcrumb'
import List from '../../../Components/List'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import PageLoading from '../../../Components/PageLoading/PageLoading'
import CreateNew from '../../../Components/CreateNew'

const PLANS = gql`
  query {
    plans {
      id
      name
      plan_code
      amount
      interval
    }
  }
`
const Plans = () => {
  let pageLoading = false
  const { loading, data, error } = useQuery(PLANS)

  if (loading) {
    pageLoading = true
  }
  
  if (error) {
    return (
      <div className="dashform-box">
        <div className="dashform">
          <div className="dashform-intro text-center">
            Well, this is embarrassing. Something went wrong. Please try again later.
          </div>
        </div>
      </div>
    )
  }

  var plansList = []

  if (data) {
    plansList = data.plans
  }

  return (
    <div>
      <Breadcrumb name="Plans" />
      <CreateNew label="Create Plans" link="/plans/new" />

      <List title="Plans" type="plans" list={plansList} />
      {pageLoading ? <PageLoading ripple={true} /> : null}
    </div>
  )
}

export default Plans
