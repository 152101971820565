import React, { useState } from 'react'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import PageLoading from '../../Components/PageLoading/PageLoading'
const RESET_PASSWORD = gql`
  mutation admin_password_reset_links($email: String!) {
    admin_password_reset_link(email: $email)
  }
`
const ForgotPassword = () => {
  const [forgotPassword, setForgotPassword] = useState({
    email: ''
  })
  let pageLoading = false
  // let authDetailsError = false
  const [userEmail, setUserEmail] = useState('')

  const [resetPassword, { loading }] = useMutation(RESET_PASSWORD)

  const handleChange = e => {
    setForgotPassword({
      ...forgotPassword,
      [e.target.name]: e.target.value
    })
  }
  const handleSubmit = e => {
    e.preventDefault()
    if (forgotPassword.email) {
      resetPassword({
        variables: { email: forgotPassword.email }
      })
      setUserEmail('Link sent successfully')
      setForgotPassword({ email: '' })
    }
  }
  if (loading) {
    pageLoading = true
  }
  return (
    <>
      <form onSubmit={handleSubmit}>
        <h1>Reset password</h1>
        <p style={{ color: 'black', fontWeight: 'bold', fontSize: '1.5rem' }}>
          {userEmail}
        </p>
        <label>Email</label>
        <input
          type="email"
          value={forgotPassword.email}
          name="email"
          onChange={handleChange}
          required
        />

        <div className="submit-box">
          <button type="submit">Reset</button>
        </div>
      </form>
      {pageLoading ? <PageLoading ripple={true} /> : null}
    </>
  )
}

export default ForgotPassword
