import React from 'react'
import NavItem from '../../../Components/Nav/NavItem'
// import '../../../../../Components/Nav/index.css'
// import { Link } from "react-router-dom";

const AdminNav = () => {
  return (
    <div className="nav">
      <div className="scrollbar"></div>
      <NavItem icon="home" label="Home" url="/home" />

      <NavItem icon="briefcase" label="Businesses" url="/businesses" />
      <NavItem icon="user-friends" label="Admins" url="/admins" />
      <NavItem icon="users" label="Consultants" url="/consultants" />

      <NavItem icon="comments" label="Supports" url="/support" />
      <NavItem icon="credit-card" url="/plans" label="Plans" />
      <NavItem icon="file" label="Transactions" url="/transactions" />
      <NavItem icon="file" label="Subscriptions" url="/subscriptions" />
      <NavItem
        icon="file-medical-alt"
        label="Health Requests"
        url="/tax-health-requests"
      />
      <NavItem
        icon="calculator"
        label="Computation Requests"
        url="/tax-computation-requests"
      />

      <NavItem icon="user" label="Users" url="/unregistered-users" />
      {/* <NavItem icon="cogs" label="Settings" url="/password-change" /> */}
    </div>
  )
}
export default AdminNav
