import React, { useState } from 'react'

import Breadcrumb from '../../../../Components/Breadcrumb/index'
import List from '../../../../Components/List/index'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import PageLoading from '../../../../Components/PageLoading/PageLoading'
const ALL_CONSULTANTS = gql`
  query all_consultants($first: Int!, $page: Int!) {
    consultants(first: $first, page: $page) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
      data {
        id
        first_name
        last_name
        email
        created_at
        updated_at
        qualification
        roles {
          name
        }
        assignments
      }
    }
  }
`

const Consultants = () => {
  const [currentPage, setCurrentPage] = useState(1)

  const { loading, error, data } = useQuery(ALL_CONSULTANTS, {
    variables: { first: 10, page: currentPage }
  })
  let pageLoading = false
  if (loading) {
    pageLoading = true
  }

  if (error) {
    console.log({ error })
    return (
      <div className="dashform-box">
        <div className="dashform">
          <div className="dashform-intro text-center">
            Well, this is embarrassing. Something went wrong. Please try again later.
          </div>
        </div>
      </div>
    )
  }
  // let consultants = data.consultants.data
  // console.log(consultants)

  let consultants = []
  let listPagination = {}
  if (data && data.consultants) {
    consultants = data.consultants.data
    listPagination = data.consultants.paginatorInfo
  }
  const handlePagination = page => {
    setCurrentPage(page)
  }
  return (
    <div>
      <Breadcrumb name="Consultants" />

      {/*
        <Card
        w="w-60"
        h="h-200"
        align="text-center"
        theme="gray"
        icon="users"
        header1="Unassigned Consultants"
        header2="32"
        link="#"
      /> */}
      <List
        type="admin-people"
        title="Consultants"
        list={consultants}
        pagination={listPagination}
        pageClicked={handlePagination}
      />
      {pageLoading ? <PageLoading ripple={true} /> : null}
    </div>
  )
}
export default Consultants
