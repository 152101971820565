import React, { useState } from 'react'
import CreateNew from '../../../Components/CreateNew'
import Breadcrumb from '../../../Components/Breadcrumb'
import List from '../../../Components/List'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import PageLoading from '../../../Components/PageLoading/PageLoading'

const ALL_CONSULTANTS = gql`
  query all_consultants($first: Int!, $page: Int!) {
    consultants(first: $first, page: $page) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
      data {
        id
        first_name
        last_name
        email
        created_at
        updated_at
        assignments
        qualification
        roles {
          name
        }
      }
    }
  }
`
const Consultants = () => {
  const [currentPage, setCurrentPage] = useState(1)
  let pageLoading = false
  const { loading, error, data } = useQuery(ALL_CONSULTANTS, {
    variables: { first: 10, page: currentPage }
  })
  if (loading) {
    pageLoading = true
  }

  if (error) {
    return (
      <div className="dashform-box">
        <div className="dashform">
          <div className="dashform-intro text-center">
            Well, this is embarrassing. Something went wrong. Please try again later.
          </div>
        </div>
      </div>
    )
  }

  let consultants = []
  let listPagination = {}
  if (data && data.consultants) {
    consultants = data.consultants.data
    listPagination = data.consultants.paginatorInfo
  }

  const handlePagination = page => {
    setCurrentPage(page)
  }

  return (
    <div>
      <Breadcrumb name="Consultants" />
      <CreateNew
        label="Total consultants"
        total={listPagination.total}
        link="/consultants/new"
      />
      <List
        type="admin-people"
        title="All consultants"
        list={consultants}
        pagination={listPagination}
        pageClicked={handlePagination}
      />
      {pageLoading ? <PageLoading ripple={true} /> : null}
    </div>
  )
}

export default Consultants
