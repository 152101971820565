import React from 'react'
import './index.css'
import { Link } from 'react-router-dom'

const createnew = props => {
  return (
    <div className="create-new-box">
      <div className="create-new">
            {props.label}: <b className="total">{props.total}</b>
            {props.link ?
              <Link to={props.link} className="create-new-link">
                Create new <i className="la la-user-plus"></i>
              </Link>
            : null}
      </div>
    </div>
  )
}

export default createnew
