import React from 'react'
import './index.css'
import { Link } from 'react-router-dom'

const breadcrumb = props => {
  return (
    <div className="crumb">
      <div className="breadcrumb">{props.name}_</div>
      {props.type === 'admin' ? (
        <div className="create-box">
          Create new<i className="la la-user-plus"></i>
          <div className="new">
            <div>
              <Link className="link" to="/admins/new">
                Create admin{/*<i className="la la-user-plus"></i>*/}
              </Link>
            </div>
            <div>
              <Link to="/consultants/new" className="link">
                Create consultant{/*<i className="la la-user-plus"></i>*/}
              </Link>
            </div>
            <div>
              {' '}
              <Link to="/plans/new" className="link">
                Create plan{/*<i className="la la-file"></i> */}
              </Link>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default breadcrumb
