import React, { useState } from 'react'
import './Support.css'
import Breadcrumb from '../../../Components/Breadcrumb/index'
import List from '../../../Components/List/index'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import PageLoading from '../../../Components/PageLoading/PageLoading'
const SUPPORT_LIST = gql`
  query supportList($first: Int!, $page: Int!) {
    list_support(first: $first, page: $page) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
      data {
        id
        support_id
        subject
        created_at
        message
        closed
        business {
          name
        }
      }
    }
  }
`
const Support = () => {
  const [currentPage, setCurrentPage] = useState(1)
  let pageLoading = false

  const {
    loading: supportLoading,
    error: supportError,
    data: supportListData
  } = useQuery(SUPPORT_LIST, {
    variables: { first: 10, page: currentPage },
    fetchPolicy: 'network-only'
  })
  if (supportLoading) {
    pageLoading = true
  }
  if (supportError) {
    return (
      <div className="dashform-box">
        <div className="dashform">
          <div className="dashform-intro text-center">
            Well, this is embarrassing. Something went wrong. Please try again later.
          </div>
        </div>
      </div>
    )
  }
  let supportList = []
  let listPagination = {}
  if (supportListData && supportListData.list_support) {
    supportList = supportListData.list_support.data
    listPagination = supportListData.list_support.paginatorInfo
    // console.log(supportListData)
  }
  // console.log(supportList)

  const handlePagination = page => {
    setCurrentPage(page)
  }

  return (
    <div>
      <Breadcrumb name="Tax support" />

      <div>
        <List
          type="support"
          title="Support history"
          list={supportList}
          // search={handleSearch}
          pagination={listPagination}
          pageClicked={handlePagination}
        />
      </div>
      {pageLoading ? <PageLoading ripple={true} /> : null}
    </div>
  )
}

export default Support
