import React from 'react'
import './index.css'
import { NavLink } from 'react-router-dom'

const navItem = props => {
  return (
    <NavLink to={props.url} className="nav-item">
      <i className={`la la-${props.icon}`}></i>
      {props.label}
    </NavLink>
  )
}

export default navItem
