import React from 'react'
import Card from '../../../Components/Card/index'
import Breadcrumb from '../../../Components/Breadcrumb/index'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import PageLoading from '../../../Components/PageLoading/PageLoading'
const GET_STATS = gql`
  {
    consultant_stats {
      thc
      p_thc #pending thc
      tcf
      p_tcf #pending tcf
      support
      o_support #open tickets
      businesses
    }
  }
`

const ConsultantHome = () => {
  const { loading, error, data } = useQuery(GET_STATS)
  let pageLoading = false
  if (loading) {
    pageLoading = true
  }
  if (error) {
    console.log(error)
  }
  let stats = {}
  if (data) {
    // console.log(data)
    stats = data.consultant_stats
  }

  return (
    <div>
      {pageLoading ? <PageLoading ripple={true} /> : null}
      <Breadcrumb name="Consultant" />
      <Card
        w="w-1of3"
        h="h-250"
        align="text-center"
        theme="purple"
        icon="file-medical-alt"
        header2={data ? String(stats.thc) : ''}
        header2min={data ? String(stats.p_thc) : ''}
        header3="Health checks"
        header3min="new requests"
        header0="Click to view Health Check requests"
        link="/tax-check"
      />
      <Card
        w="w-1of3"
        h="h-250"
        align="text-center"
        theme="purple"
        icon="calculator"
        header2={data ? String(stats.tcf) : ''}
        header2min={data ? String(stats.p_tcf) : ''}
        header3="Computations"
        header3min="new requests"
        header0="Click to perform computations"
        link="/computation-requests"
      />

      <Card
        w="w-1of3"
        h="h-250"
        align="text-center"
        theme="purple"
        icon="comments"
        header2={data ? String(stats.support) : ''}
        header2min={data ? String(stats.o_support) : ''}
        header3="Support"
        header3min="Active supports"
        header0="Click for support"
        link="/support"
      />
      <Card
        w="w-1of3"
        h="h-250"
        align="text-center"
        theme="purple"
        icon="briefcase"
        header2={data ? String(stats.businesses) : ''}
        header2min=""
        header3="Businesses"
        header3min=""
        header0="Click to view business list"
        link="/businesses"
      />
    </div>
  )
}
export default ConsultantHome
