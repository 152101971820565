import React, { useState, useEffect } from 'react'
import './index.css'
import { Link } from 'react-router-dom'
import InputRange from 'react-input-range'
import 'react-input-range/lib/css/index.css'

const List = props => {
  const [componentState, setComponentState] = useState({
    listSearch: {
      validation: { required: true },
      value: '',
      valid: false,
      touched: false
    },
    searchIsValid: false
  })
  // console.log(props.turnOver)

  // const [turnOverTouched, setTurnOverTouched] = useState(false)
  const [inputRangeValue, setInputRangeValue] = useState({
    min: 0,
    max: 0
  })

  useEffect(() => {
    if (props.turnOver) {
      setInputRangeValue({
        min: Number(props.turnOver[0]),
        max: Number(props.turnOver[1])
      })
    }
  }, [props.turnOver])

  const inputChangedHandler = event => {
    const updatedlistSearch = {
      ...componentState.listSearch
    }
    updatedlistSearch.value = event.target.value
    updatedlistSearch.valid = checkValidity(updatedlistSearch.value)
    updatedlistSearch.touched = true

    setComponentState({
      listSearch: updatedlistSearch,
      searchIsValid: updatedlistSearch.valid
    })
  }
  const checkValidity = value => {
    let isValid = true
    isValid = value.trim() !== '' && isValid
    return isValid
  }
  // console.log(props.pagination)
  return (
    <div className={`list-box ${props.w} `}>
      <div className={`list ${props.h} `}>
        <div className="list-head">
          <div className="list-name">
            {props.title}

            <div className="search-box">
              {props.filter ? (
                <div
                  className={`filter ${
                    props.filteredItems.length > 0 ? 'active' : null
                  }`}
                >
                  <i className="la la-filter"></i>

                  {props.type === 'super-businesses' ||
                  props.type === 'businesses' ? (
                    <>
                      <i
                        className="la la-redo-alt"
                        onClick={props.clear}
                        title="Clear filter"
                      ></i>
                      <div className="filter-list">
                        {props.filter.map(filterGroup => {
                          return (
                            <div key={filterGroup.title}>
                              <h3>{filterGroup.title}</h3>
                              {filterGroup.title === 'TURNOVER' ? (
                                <>
                                  <InputRange
                                    // type="range"
                                    step={5000000}
                                    value={inputRangeValue}
                                    minValue={Number(filterGroup.items[0])}
                                    maxValue={Number(filterGroup.items[1])}
                                    onChange={value => {
                                      setInputRangeValue(value)
                                      // setTurnOverTouched(true)
                                    }}
                                  />

                                  <div className="filter-btn-container">
                                    <button
                                      className="filter-btn"
                                      onClick={() =>
                                        props.filterClicked(
                                          inputRangeValue,
                                          filterGroup.title
                                        )
                                      }
                                    >
                                      filter
                                    </button>
                                  </div>
                                </>
                              ) : (
                                filterGroup.items.map(filterElement => {
                                  return (
                                    <label className="item" key={filterElement}>
                                      <>
                                        {filterElement}
                                        <input
                                          type="checkbox"
                                          value={filterElement}
                                          checked={props.filteredItems.includes(
                                            filterElement
                                          )}
                                          onChange={event =>
                                            props.filterClicked(
                                              event,
                                              filterGroup.title
                                            )
                                          }
                                        />
                                      </>
                                    </label>
                                  )
                                })
                              )}
                            </div>
                          )
                        })}
                      </div>
                    </>
                  ) : props.type === 'transactions' ||
                    props.type === 'subscriptions' ? (
                    <>
                      <i
                        className="la la-redo-alt"
                        onClick={props.clear}
                        title="Clear filter"
                      ></i>
                      <div className="filter-list">
                        {props.filter.map(filterGroup => {
                          return (
                            <div key={filterGroup.title}>
                              <h3>{filterGroup.title}</h3>
                              {filterGroup.items.map(filterElement => {
                                return (
                                  <label className="item" key={filterElement}>
                                    <>
                                      {filterElement}
                                      <input
                                        type="checkbox"
                                        value={filterElement}
                                        checked={props.filteredItems.includes(
                                          filterElement
                                        )}
                                        onChange={event => {
                                          props.filterClicked(
                                            event.target.value
                                          )
                                        }}
                                      />
                                    </>
                                  </label>
                                )
                              })}
                            </div>
                          )
                        })}
                      </div>
                    </>
                  ) : null}
                </div>
              ) : null}
              {props.search ? (
                <form
                  className="list-search"
                  onSubmit={event =>
                    props.search(event, componentState.listSearch.value)
                  }
                >
                  <input
                    type="search"
                    placeholder="Search list"
                    onChange={event => inputChangedHandler(event)}
                  />
                  <button
                    type="submit"
                    disabled={!componentState.searchIsValid}
                  >
                    <i className="la la-search"></i>
                  </button>
                </form>
              ) : null}
            </div>
          </div>
          <div className="list-header">
            {props.link ? (
              <Link to={props.link}>
                <i className="las la-plus"></i>
              </Link>
            ) : null}

            {props.type === 'consultant-businesses' ? (
              <>
                <div className="w-30 f-name">Name</div>
                <div className="w-20 f-name">Type</div>
                <div className="w-20 f-name">Created At</div>
                <div className="w-15 f-name">Tier</div>
              </>
            ) : null}

            {props.type === 'super-businesses' ? (
              <>
                <div className="w-30 f-name">Business</div>
                <div className="w-25 f-name">Phone</div>
                <div className="w-22 f-name">Name</div>
                <div className="w-10 f-name">Tier</div>
                <div className="w-13 f-name">Status</div>
              </>
            ) : props.type === 'businesses' ? (
              <>
                <div className="w-30 f-name">Business</div>
                <div className="w-25 f-name">Type</div>
                <div className="w-22 f-name">Created</div>
                <div className="w-10 f-name">Tier</div>
                <div className="w-13 f-name">Status</div>
              </>
            ) : props.type === 'super' ||
              props.type === 'people' ||
              props.type === 'users' ||
              props.type === 'admin-people' ? (
              <div className="w-20 f-name">Firstname</div>
            ) : null}

            {props.type === 'requests' ||
            props.type === 'computations' ||
            props.type === 'admin-requests' ? (
              <div className="w-30 f-name">Type</div>
            ) : null}

            {props.type === 'requests' ||
            props.type === 'computations' ||
            props.type === 'admin-requests' ? (
              <div className="w-30 f-name">Request Date</div>
            ) : null}

            {props.type === 'requests' ||
            props.type === 'computations' ||
            props.type === 'admin-requests' ? (
              <div className="w-15 f-name">Name</div>
            ) : null}

            {props.type === 'plans' ? (
              <>
                <div className="w-20 f-name">Name</div>
                <div className="w-20 f-name">Interval</div>
                <div className="w-22 f-name">Plan code</div>
                <div className="w-20 f-name">Amount</div>
              </>
            ) : null}

            {props.type === 'transactions' ? (
              <>
                <div className="w-20 f-name">Business</div>
                <div className="w-13 f-name">Amount</div>
                <div className="w-22 f-name">Plan </div>
                <div className="w-15 f-name">Reference</div>

                <div className="w-10 f-name">Status</div>
                <div className="w-15 f-name">Date</div>
              </>
            ) : null}

            {props.type === 'subscriptions' ? (
              <>
                <div className="w-15 f-name">Business</div>
                <div className="w-21 f-name">Code</div>
                <div className="w-10 f-name">Amount </div>
                <div className="w-12 f-name">Plan </div>
                <div className="w-15 f-name">Start date </div>
                <div className="w-15 f-name">End date</div>

                <div className="w-11 f-name">Status</div>
              </>
            ) : null}

            {props.type === 'support' ? (
              <>
                <div className="w-25 f-name">Date</div>
                <div className="w-30 f-name">Name</div>
                <div className="w-20 f-name">Subject</div>
                <div className="w-15 f-name">Status</div>
              </>
            ) : null}

            {props.type === 'super' ||
            props.type === 'people' ||
            props.type === 'users' ||
            props.type === 'admin-people' ? (
              <>
                {' '}
                <div className="w-20 f-name">Lastname</div>
                <div className="w-20 f-name">Email</div>
              </>
            ) : null}

            {props.type === 'admin-people' || props.type === 'people' ? (
              <div className="w-20 con-role f-name">Role</div>
            ) : null}

            {props.type === 'requests' ||
            props.type === 'computations' ||
            props.type === 'admin-requests' ? (
              <div className="w-25 comp f-name">Status</div>
            ) : null}
            
          </div>
        </div>
        {props.list.length > 0 ? (
          <div className="list-item-box">
            {props.list.map((listItem, index) => {
              let path
              if (props.type === 'businesses') {
                if (listItem.verified) {
                  listItem['theme'] = 'green'
                } else {
                  listItem['theme'] = 'red'
                }
                path = `/businesses/${listItem.slug}`
              } else if (props.type === 'requests') {
                if (listItem.report === null) {
                  listItem['theme'] = 'red'
                } else {
                  listItem['theme'] = 'green'
                }
                path = `/tax-check/${listItem.id}`
              } else if (props.type === 'computations') {
                if (listItem.report === null) {
                  listItem['theme'] = 'red'
                } else {
                  listItem['theme'] = 'green'
                }
                path = `/computation-requests/${listItem.id}`
              } else if (props.type === 'admin-people') {
                path = `/consultants/${Number(listItem.id)}`
              } else if (props.type === 'super') {
                path = `/admins/${Number(listItem.id)}`
              } else if (props.type === 'people') {
                path = `/admins/${Number(listItem.id)}`
              } else if (props.type === 'plans') {
                path = `/plans/${Number(listItem.id)}`
              } else if (props.type === 'super-businesses') {
                if (listItem.verified) {
                  listItem['theme'] = 'green'
                } else {
                  listItem['theme'] = 'red'
                }
                path = `/businesses/${listItem.slug}`
              } else if (props.type === 'consultant-businesses') {
                path = `/businesses/${listItem.slug}`
              } else if (props.type === 'support') {
                if (listItem.closed) {
                  listItem['theme'] = 'green'
                } else {
                  listItem['theme'] = 'red'
                }
                path = `/support/${listItem.support_id}`
              } else {
                path = '#'
              }
              return (
                <Link to={path} className="list-item" key={index}>
                  {props.type === 'consultant-businesses' ? (
                    <>
                      {' '}
                      <div className="w-30">
                        {' '}
                        <b className="visible-xs">Name: </b> {listItem.name}
                      </div>
                      <div className="w-20">
                        {' '}
                        <b className="visible-xs">Type: </b> {listItem.type}
                      </div>
                      <div className="w-20">
                        <>
                          <b className="visible-xs">Date: </b>
                          {new Date(listItem.created_at).toDateString()}
                        </>
                      </div>
                      <div className="w-15">
                        <b className="visible-xs">Tier: </b> {listItem.tier}
                      </div>
                    </>
                  ) : null}

                  {props.type === 'super-businesses' ? (
                    <>
                      {' '}
                      <div className="w-30">
                        {' '}
                        <b className="visible-xs">Business: </b>{' '}
                        {listItem.name ? listItem.name : 'N/A'}
                      </div>
                      <div className="w-25">
                        {' '}
                        <b className="visible-xs">Phone: </b>{' '}
                        {listItem.phone ? listItem.phone : 'N/A'}
                      </div>
                      <div className="w-22">
                        <>
                          <b className="visible-xs">Name: </b>
                          {listItem.user
                            ? `  ${listItem.user.first_name} ${listItem.user.last_name}`
                            : 'N/A'}
                        </>
                      </div>
                      <div className="w-10">
                        <b className="visible-xs">Tier: </b>{' '}
                        {listItem.tier ? listItem.tier : 'N/A'}
                      </div>
                      <div className="w-13">
                        <>
                          <b className="visible-xs">Status: </b>
                          <b
                            className={`list-item-score-value ${listItem.theme}`}
                          >
                            {listItem.verified ? 'verified' : 'unverified'}
                          </b>
                        </>
                      </div>
                    </>
                  ) : props.type === 'businesses' ? (
                    <>
                      {' '}
                      <div className="w-30">
                        {' '}
                        <b className="visible-xs">Business: </b> {listItem.name}
                      </div>
                      <div className="w-25">
                        {' '}
                        <b className="visible-xs">Type: </b> {listItem.type}
                      </div>
                      <div className="w-22">
                        <>
                          <b className="visible-xs">Created: </b>
                          {new Date(listItem.created_at).toDateString()}
                        </>
                      </div>
                      <div className="w-10">
                        <b className="visible-xs">Tier: </b> {listItem.tier}
                      </div>
                      <div className="w-13">
                        <>
                          <b className="visible-xs">Status: </b>
                          <b
                            className={`list-item-score-value ${listItem.theme}`}
                          >
                            {listItem.verified ? 'verified' : 'unverified'}
                          </b>
                        </>
                      </div>
                    </>
                  ) : null}

                  {props.type === 'super' ||
                  props.type === 'people' ||
                  props.type === 'users' ||
                  props.type === 'admin-people' ? (
                    <>
                      <div className="w-20 first">
                        {' '}
                        <b className="visible-xs">Firstname: </b>{' '}
                        {listItem.first_name}
                      </div>
                      <div className="w-18 laste">
                        <b className="visible-xs">Lastname: </b>{' '}
                        {listItem.last_name}
                      </div>
                    </>

                  ) : props.type === 'requests' ||
                    props.type === 'computations' ||
                    props.type === 'admin-requests' ? (
                    <div className="w-30">
                      <b className="visible-xs">Type: </b> {listItem.type}
                    </div>
                  ) : null}

                  {props.type === 'computations' ||
                  props.type === 'requests' ||
                  props.type === 'admin-requests' ? (
                    <div className="w-30 date">
                      <b className="visible-xs">Date: </b>
                      {new Date(listItem.created_at).toDateString()}
                    </div>
                  ) : null}

                  <div className="w-25">
                    {props.type === 'computations' ||
                    props.type === 'requests' ||
                    props.type === 'admin-requests' ? (
                      <span>
                        <b className="visible-xs">Name: </b>{' '}
                        {listItem.business.name}
                      </span>
                    ) : null}

                    {props.type === 'super' ||
                    props.type === 'users' ||
                    props.type === 'people' ||
                    props.type === 'admin-people' ? (
                      <>
                        <b className="visible-xs">Email: </b> {listItem.email}
                      </>
                    ) : null}
                    {props.type === 'people' ? (
                      <span>
                        <b className="visible-xs">Clients: </b>{' '}
                        {listItem.clients}
                      </span>
                    ) : null}
                  </div>

                  {props.type === 'requests' ||
                  props.type === 'computations' ||
                  props.type === 'admin-requests' ? (
                    <div className="w-10">
                      <b className="visible-xs">Status: </b>
                      <b className={`list-item-score-value ${listItem.theme}`}>
                        {!listItem.report ? 'pending' : 'resolved'}
                      </b>
                    </div>
                  ) : null}

                  {props.type === 'plans' ? (
                    <>
                      <div className="w-20">
                        <b className="visible-xs">Name: </b>
                        {listItem.name}
                      </div>

                      <div className="w-20">
                        <b className="visible-xs">Interval: </b>
                        {listItem.interval}
                      </div>
                      
                      {listItem.plan_code ? (
                        <div className="w-22">
                          <b className="visible-xs">Plan Code: </b>
                          {listItem.plan_code}
                        </div>
                      ) : (
                        <div className="w-20">
                          <b className="visible-xs">Plan Code: </b>
                          N/A
                        </div>
                      )}

                      <div className="w-20">
                        <b className="visible-xs">Amount: </b>
                        <span className="naira bold">&#8358;</span>
                        {listItem.amount}
                      </div>
                    </>
                  ) : null}

                  {props.type === 'transactions' ? (
                    <>
                      <div className="w-20">
                        {' '}
                        <b className="visible-xs">Business: </b>
                        {listItem.business}
                      </div>
                      {listItem.amount ? (
                        <div className="w-13">
                          {' '}
                          <b className="visible-xs">Amount: </b>
                          <span className="naira bold">&#8358;</span>
                          {listItem.amount / 100}
                        </div>
                      ) : (
                        <div className="w-13">
                          {' '}
                          <b className="visible-xs">Amount: </b>
                          N/A
                        </div>
                      )}
                      {listItem.plan ? (
                        <div className="w-22">
                          {' '}
                          <b className="visible-xs">Plan: </b>
                          {listItem.plan}{' '}
                        </div>
                      ) : (
                        <div className="w-22">
                          {' '}
                          <b className="visible-xs">Plan: </b>
                          N/A
                        </div>
                      )}

                      <div className="w-15">
                        {' '}
                        <b className="visible-xs">Reference: </b>
                        {listItem.reference ? listItem.reference : 'N/A'}
                      </div>

                      <div className="w-10">
                        {' '}
                        <b className="visible-xs">Status: </b>
                        {listItem.status}
                      </div>
                      <div className="w-15">
                        {' '}
                        <b className="visible-xs">Date: </b>
                        {new Date(listItem.date).toDateString()}
                      </div>
                    </>
                  ) : null}

                  {props.type === 'support' ? (
                    <>
                      <div className="w-25">
                        {' '}
                        <b className="visible-xs">Date: </b>
                        {new Date(listItem.created_at).toDateString()}
                      </div>
                      <div className="w-30">
                        <b className="visible-xs">Name: </b>
                        {listItem.business.name}
                      </div>
                      <div className="w-20">
                        <b className="visible-xs">Subject: </b>{' '}
                        {listItem.subject}
                      </div>
                      <div className="w-15">
                        <b className="visible-xs">Status: </b>
                        <b
                          className={`list-item-score-value ${listItem.theme}`}
                        >
                          {listItem.closed ? 'resolved' : 'open'}
                        </b>
                      </div>
                    </>
                  ) : null}

                  {props.type === 'subscriptions' ? (
                    <>
                      <div className="w-15 list-item-name">
                        <b className="visible-xs">Business: </b>
                        {listItem.business ? listItem.business : 'N/A'}
                      </div>
                      <div className="w-21">
                        <b className="visible-xs">Code: </b>
                        {listItem.code ? listItem.code : 'N/A'}
                      </div>
                      <div className="w-10">
                        {' '}
                        <b className="visible-xs">Amount: </b>
                        <span className="naira bold">&#8358;</span>
                        {listItem.amount / 100}
                      </div>
                      {listItem.plan ? (
                        <div className="w-12">
                          {' '}
                          <b className="visible-xs">Plan: </b>
                          {listItem.plan ? listItem.plan : 'N/A'}{' '}
                        </div>
                      ) : (
                        <div className="w-12">
                          {' '}
                          <b className="visible-xs">Plan: </b>
                          N/A
                        </div>
                      )}
                      <div className="w-15">
                        <b className="visible-xs">Start date: </b>
                        {new Date(listItem.start).toDateString()}
                      </div>
                      <div className="w-15">
                        <b className="visible-xs">End date: </b>
                        {new Date(listItem.end).toDateString()}
                      </div>
                      <div className="w-11">
                        <b className="visible-xs">Status: </b>
                        {listItem.status ? listItem.status : 'N/A'}
                      </div>
                    </>
                  ) : null}

                  <div className="w-18 role">
                    {props.type === 'admin-people' ||
                    props.type === 'people' ? (
                      <>
                        <b className="visible-xs">Role: </b>{' '}
                        {listItem.roles[0].name}
                      </>
                    ) : null}
                  </div>
                </Link>
              )
            })}

          </div>
        ) : (

          <div
            className="w-100"
            style={{ textAlign: 'center', letterSpacing: '1px', color: 'grey' }}
          >
            Empty list
          </div>
        )}

        {props.pagination ? (
          <div className="list-pagination">
            {props.pagination.total > 0 ? (
              <>
                {props.pagination.currentPage > 1 ? (
                  <button
                    onClick={() =>
                      props.pageClicked(props.pagination.currentPage - 1)
                    }
                  >
                    <i className="la la-angle-double-left"></i>Prev
                  </button>
                ) : null}
                {props.pagination.currentPage - 2 > 1 ? (
                  <>
                    <button onClick={() => props.pageClicked(1)}>1</button>...
                  </>
                ) : null}
                {props.pagination.currentPage > 1 ? (
                  <button
                    onClick={() =>
                      props.pageClicked(props.pagination.currentPage - 1)
                    }
                  >
                    {props.pagination.currentPage - 1}
                  </button>
                ) : null}

                <button className="active">
                  {props.pagination.currentPage}
                </button>

                {props.pagination.currentPage < props.pagination.lastPage ? (
                  <button
                    onClick={() =>
                      props.pageClicked(props.pagination.currentPage + 1)
                    }
                  >
                    {props.pagination.currentPage + 1}
                  </button>
                ) : null}
                {props.pagination.currentPage === 1 &&
                props.pagination.lastPage > 2 ? (
                  <button
                    onClick={() =>
                      props.pageClicked(props.pagination.currentPage + 2)
                    }
                  >
                    {props.pagination.currentPage + 2}
                  </button>
                ) : null}
                {props.pagination.currentPage + 2 <
                props.pagination.lastPage ? (
                  <>
                    ...
                    <button
                      onClick={() =>
                        props.pageClicked(props.pagination.lastPage)
                      }
                    >
                      {props.pagination.lastPage}
                    </button>
                  </>
                ) : null}
                {props.pagination.currentPage < props.pagination.lastPage ? (
                  <button
                    onClick={() =>
                      props.pageClicked(props.pagination.currentPage + 1)
                    }
                  >
                    Next<i className="la la-angle-double-right"></i>
                  </button>
                ) : null}
              </>
            ) : null}
          </div>
        ) : null}

      </div>
    </div>
  )
}

export default List
