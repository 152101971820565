import React, { useState } from 'react'
import Breadcrumb from '../../../../Components/Breadcrumb/index'
import CKEditor from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
// import ReactHtmlParser from 'react-html-parser'
import '../Business.css'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import PageLoading from '../../../../Components/PageLoading/PageLoading'
const SCORE_TAX_HEALTH = gql`
  mutation scoreTaxHealth($id: Int!, $input: ScoreTaxInput!) {
    score_tax_health(id: $id, input: $input) {
      id
      type
      assessment
      score
      report
      created_at
      updated_at
      business {
        id
        user_id
        name
        slug
        type
        employees
        turnover
        designation
        bvn
        rc_no
        bn_no
        tin
        vat_reg_no
        address
        tier
        verified
        created_at
        updated_at
        cac_docs {
          files
        }
      }
    }
  }
`

const TaxHealth = props => {
  const {
    match: {
      params: { id }
    }
  } = props
  // console.log(id)
  const [taxHealth, setTaxHealth] = useState({ score: 0, report: '' })
  const [value, setValue] = useState('')
  let isReviewSent = false
  const [scoreTaxHealth, { loading, error, data }] = useMutation(
    SCORE_TAX_HEALTH
  )
  let pageLoading = false
  const handleChange = (e, editor) => {
    const data = editor.getData()

    setValue(data)
  }
  const handleNewChange = e => {
    const { value } = e.target
    setTaxHealth({ score: value })
  }
  const handleAssesementSubmit = e => {
    e.preventDefault()
    const editorText = value.replace(/(<([^>]+)>)/gi, '')
    taxHealth.report = editorText
    taxHealth.score = Number(taxHealth.score)
    console.log(taxHealth)
    scoreTaxHealth({
      variables: {
        id: id,
        input: taxHealth
      }
    })
    // props.history.push(`/tax-check/${id}`)
  }
  if (loading) {
    pageLoading = true
  }
  if (error) {
    return <p>Error</p>
  }
  if (data) {
    isReviewSent = true
  }
  return (
    <div>
      <Breadcrumb name="Assesssment" />
      {!isReviewSent ? (
        <form onSubmit={handleAssesementSubmit} className="form-data">
          <label>Score</label>
          <input type="text" onChange={handleNewChange} required />
          <label>Report</label>
          <CKEditor editor={ClassicEditor} onChange={handleChange} />
          <button className="form-btn report">Submit</button>
        </form>
      ) : (
        <div className="view">
          <div className="view-inner">
            <p>Review sent</p>
          </div>
        </div>
      )}
      {pageLoading ? <PageLoading ripple={true} /> : null}
    </div>
  )
}
export default TaxHealth
