import React, { useEffect } from 'react'
import './support.css'
import { animateScroll } from 'react-scroll'

import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import PageLoading from '../../../Components/PageLoading/PageLoading'
const SUPPORT_TICKET = gql`
  query($support_id: String!) {
    view_support(support_id: $support_id) {
      support_id
      subject
      message
      closed
      created_at
      updated_at
    }
  }
`

const SupportTicket = props => {
  const ticketId = props.match.params.id

  let pageLoading = false
  const {
    laoding: ticketLoading,
    error: ticketError,
    data: ticketData
  } = useQuery(SUPPORT_TICKET, {
    variables: { support_id: ticketId },
    pollInterval: 2000
  })
  if (ticketLoading) {
    // pageLoading = true;
  }
  if (ticketError) {
    console.log(ticketError)
  }
  // if (closeData) {
  //   isSupportClosed = true
  // }
  let ticket = {}
  let messages = []
  if (ticketData && ticketData.view_support) {
    ticket = ticketData.view_support
    messages = JSON.parse(ticket.message)
  }
  // if (closeLoading) {
  //   pageLoading = true
  // }
  useEffect(() => {
    animateScroll.scrollToBottom({
      containerId: 'message-thread',
      duration: 300
    })
  })

  return (
    <div>
      {/* <Breadcrumb name={"Ticket ID: " + ticketId} /> */}

      <div className="ticket-box">
        <div className={'ticket ' + (ticket.closed ? 'closed' : null)}>
          <div className="ticket-head">
            <div className="ticket-name">{ticket.subject} </div>

            <div className="ticket-info hidden-xs">
              <span className="item">
                Status:{' '}
                {ticket.closed ? (
                  <b className="green">RESOLVED</b>
                ) : (
                  <b className="red">OPEN</b>
                )}
              </span>
              <span className="item">
                Created on:{' '}
                <b className="purple">
                  {new Date(ticket.created_at).toLocaleString()}
                </b>
              </span>
            </div>
          </div>

          <div className="ticket-thread" id="message-thread">
            <div className="users">
              <span>Client</span>
              <span>Consultant</span>
            </div>
            {messages.map(message => {
              return (
                <div key={message.time}>
                  <div className={'message ' + message.from}>
                    {message.message}
                    <span className="time">
                      {new Date(message.time).toLocaleString()}
                    </span>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
      {pageLoading ? <PageLoading ripple={true} /> : null}
    </div>
  )
}

export default SupportTicket
