import React, { useState } from 'react'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import PageLoading from '../../../Components/PageLoading/PageLoading'
import Breadcrumb from '../../../Components/Breadcrumb/index'
const PASSWORD_CHANGE = gql`
  mutation password_change($input: resetPasswordInput!) {
    admin_reset_password(input: $input)
  }
`
const NewPassword = () => {
  const [newPassword, setNewPassword] = useState({
    token: '',
    email: '',
    password: '',
    password_confirmation: ''
  })
  const [passwordError, setPasswordError] = useState({
    password_matchError: '',
    password_lengthError: ''
  })
  let pageLoading = false
  let ispasswordChanged = false
  const [resetPassword, { loading, data }] = useMutation(PASSWORD_CHANGE)
  const handleChange = e => {
    setNewPassword({
      ...newPassword,
      [e.target.name]: e.target.value
    })
  }

  const valid = () => {
    let password_matchError = ''
    let password_lengthError = ''
    if (newPassword.password !== newPassword.password_confirmation) {
      password_matchError = 'Password does not match'
    }
    if (
      newPassword.password.length < 8 &&
      newPassword.password_confirmation.length < 8
    ) {
      password_lengthError = 'Password must be more than 8 characters'
    }
    if (password_matchError || password_lengthError) {
      setPasswordError({ password_matchError, password_lengthError })
      return false
    }
    return true
  }
  const handleFormSubmit = e => {
    e.preventDefault()
    let token = JSON.parse(localStorage.getItem('token'))
    const isValid = valid()
    if (isValid) {
      newPassword.token = token

      resetPassword({ variables: { input: newPassword } })
    }
  }
  if (loading) {
    pageLoading = true
  }
  if (data) {
    ispasswordChanged = true
  }
  return (
    <div className="pages">
      <Breadcrumb name="Change Password" />
      {!ispasswordChanged ? (
        <form onSubmit={handleFormSubmit} className="dashform-box w-50">
          <div className="dashform update-profile">
            <div className="dashform-intro">Change password</div>
            <p className="error">{passwordError.password_lengthError}</p>
            <label>Email</label>
            <input
              name="email"
              type="text"
              value={newPassword.email}
              onChange={handleChange}
              // onBlur={handleChange}
              required
            />
            <label>Password</label>
            <input
              name="password"
              type="password"
              value={newPassword.password}
              onChange={handleChange}
              // onBlur={handleChange}
              required
              id="password"
            />
            <p className="error">{passwordError.password_matchError}</p>
            <label>Confirm Password</label>
            <input
              name="password_confirmation"
              type="password"
              value={newPassword.password_confirmation}
              onChange={handleChange}
              // onBlur={handleChange}
              required
            />
            <div className="submit-box">
              <button type="submit">Submit</button>{' '}
            </div>
          </div>
        </form>
      ) : (
        <p>Password Changed</p>
      )}
      {pageLoading ? <PageLoading ripple={true} /> : null}
    </div>
  )
}
export default NewPassword
