import React, { useState } from 'react'
import gql from 'graphql-tag'
import { useQuery, useMutation } from '@apollo/react-hooks'
// import Breadcrumb from '../../../Components/Breadcrumb/index'
import PageLoading from '../../../Components/PageLoading/PageLoading'
// import { Link } from 'react-router-dom'
import '../../Admin/Businesses/index.css'

const ONE_BUSINESS = gql`
  query one_Business($slug: String!) {
    business(slug: $slug) {
      id
      user_id
      name
      slug
      type
      employees
      turnover
      designation
      subscribed
      rc_no
      bn_no
      tin
      vat_reg_no
      address
      tier
      verified
      created_at
      updated_at
      cac_docs {
        files
      }
      user {
        id
        first_name
        last_name
        email
        identity
        created_at
        updated_at
      }
    }
  }
`

const DELETE_BUSINESS = gql`
  mutation delete_Business($business_id: Int!) {
    delete_business(business_id: $business_id)
  }
`
const GET_BUSINESSES = gql`
  query filterBusiness(
    $column: BusinessColumn
    $operator: SQLOperator
    $value: Mixed
    $first: Int!
    $page: Int!
  ) {
    filter_businesses(
      where: { column: $column, operator: $operator, value: $value }
      orderBy: [{ field: ID, order: DESC }]
      first: $first
      page: $page
    ) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
      data {
        id
        user_id
        name
        slug
        type
        employees
        turnover
        designation
        rc_no
        bn_no
        tin
        vat_reg_no
        address
        tier
        subscribed
        verified
        phone
        created_at
        updated_at
        cac_docs {
          files
        }
        user {
          id
          first_name
          last_name
          email
          identity
          created_at
          updated_at
        }
      }
    }
  }
`

const SingleBusiness = props => {
  const { slug } = props.match.params
  const [timePressed, setTimePressed] = useState(0)
  const [currentPage] = useState(1)
  const { loading, data } = useQuery(ONE_BUSINESS, {
    variables: { slug: slug }
  })
  const [deleteBusiness] = useMutation(DELETE_BUSINESS, {
    refetchQueries: [
      {
        query: GET_BUSINESSES,
        variables: {
          first: 15,
          page: currentPage
        }
      }
    ]
  })
  let pageLoading = false
  if (loading) {
    pageLoading = true
  }

  let business = {}
  // const { cac_docs } = business

  let documents = []

  if (data) {
    business = data.business;

    const docsArray = [];
    business.cac_docs.forEach(element => {
        docsArray.push(JSON.parse(element.files));
    });
    
    const files = business.cac_docs[0] ? Object.assign(...docsArray, {}) : null;
    if (files) {
      for (const key in files) {
          documents.push({
              name: key, path: files[key]
          })
      }
    }
    documents.reverse();
  }
  
  const onPress = () => {
    setTimePressed(timePressed + 1)
    if (timePressed === 1) {
      deleteBusiness({
        variables: {
          business_id: business.id
        }
      })

      props.history.push('/businesses')
    }
  }
  const text = ['Delete ', 'Confirm Delete']
  return (
    <div>
      {data ? (
        <div className="profile-box">
          <div className="profile">
            <div className="profile-intro">
              <div className="desc big">
                <div className="label">Business name</div>
                <div className="value">
                  {business.name ? business.name : 'N/A'}
                </div>
              </div>
              <div className="desc wide">
                <div className="label">Business address</div>
                <div className="value editable">
                  {business.address ? business.address : 'N/A'}
                </div>
              </div>
              <div className="desc wide">
                <div className="label">Email</div>
                <div className="value editable">
                  {business.user.email ? business.user.email : 'N/A'}
                </div>
              </div>
              {business.user.first_name && business.user.last_name && (
                <div className="desc wide-mob">
                  <div className="label">User</div>
                  <div className="value editable">
                    {business.user.first_name} {business.user.last_name}
                  </div>
                </div>
              )}

              <div className="desc wide-mob">
                <div className="label">Phone number</div>
                <div className="value editable">
                  {business.phone ? business.phone : 'N/A'}
                </div>
              </div>
              <div className="desc wide-mob">
                <div className="label">Yearly turnover</div>
                <div className="value editable">
                  <span className="naira">&#8358;</span>
                  {business.turnover ? business.turnover : 'N/A'}
                </div>
              </div>

              <div className="desc">
                <div className="label">Business tier</div>
                <div className="value editable">{business.tier}</div>
              </div>
              <div className="desc">
                <div className="label">Business type</div>
                <div className="value">{business.type}</div>
              </div>
              <div className="desc">
                <div className="label">Employees</div>
                <div className="value editable">{business.employees}</div>
              </div>

              {business.subscribed ? (
                <div className="desc">
                  <div className="label">Subscription</div>
                  <div className="value editable">Subscribed</div>
                </div>
              ) : (
                <div className="desc">
                  <div className="label">Subscription</div>
                  <div className="value editable">Not subscribed</div>
                </div>
              )}
              {business.rc_no && (
                <div className="desc">
                  <div className="label">RC number</div>
                  <div className="value">{business.rc_no}</div>
                </div>
              )}

              {business.bn_no ? (
                <div className="desc">
                  <div className="label">BN number</div>
                  <div className="value">{business.bn_no}</div>
                </div>
              ) : null}

              <div className="desc">
                <div className="label">TIN</div>
                <div className="value">
                  {business.tin ? business.tin : 'N/A'}
                </div>
              </div>
              <div className="desc">
                <div className="label">VAT number</div>
                <div className="value">
                  {business.vat_reg_no ? business.vat_reg_no : 'N/A'}
                </div>
              </div>
              <div className="desc">
                <div className="label">Created </div>
                <div className="value">
                  {new Date(business.created_at).toDateString()}
                </div>
              </div>
              <div className="desc">
                <div className="label">Updated </div>
                <div className="value">
                  {new Date(business.updated_at).toDateString()}
                </div>
              </div>
            </div>
            <div className="profile-section">
              <h1 className="title">User Identification</h1>

              <div className="profile-text">
                <div className="profile-assessment">
                  <div className="question">User Id</div>
                  <div className="answer">
                    <a
                      target="_blank"
                      href={business.user.identity}
                      rel="noopener noreferrer"
                    >
                      Download <i className="la la-download"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="profile-section">
              <h1 className="title">Business documents</h1>

              <div className="profile-text">
                {documents &&
                  documents.map(document => {
                    return (
                      <div className="profile-assessment" key={document.name}>
                        <div className="question">{document.name}</div>
                        <div className="answer">
                          <a
                            target="_blank"
                            href={document.path}
                            rel="noopener noreferrer"
                          >
                            Download <i className="la la-download"></i>
                          </a>
                        </div>
                      </div>
                    )
                  })}
              </div>
            </div>
            <div className="profile-links">
              <button className="profile-link b purple" onClick={onPress}>
                {' '}
                {text[timePressed]}
              </button>
            </div>
          </div>
        </div>
      ) : null}
      {pageLoading ? <PageLoading ripple={true} /> : null}
    </div>
  )
}

export default SingleBusiness
