import React, { useState } from 'react'
import Breadcrumb from '../../../Components/Breadcrumb'
import List from '../../../Components/List'
import gql from 'graphql-tag'
import PageLoading from '../../../Components/PageLoading/PageLoading'
import { useQuery } from '@apollo/react-hooks'

const BUSINESSES = gql`
  query Businesses($first: Int!, $page: Int!) {
    businesses(
      orderBy: [{ field: ID, order: DESC }]
      first: $first
      page: $page
    ) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
        __typename
      }
      data {
        id
        user_id
        name
        slug
        type
        employees
        turnover
        designation
        rc_no
        bn_no
        tin
        vat_reg_no
        address
        tier
        verified
        created_at
        updated_at
        cac_docs {
          files
        }
        subscribed
      }
    }
  }
`

const Businesses = () => {
  const [currentPage, setCurrentPage] = useState(1)
  let pageLoading = false
  const { loading, error, data } = useQuery(BUSINESSES, {
    variables: { first: 5, page: currentPage },
    fetchPolicy: 'cache-and-network'
  })
  if (loading) {
    pageLoading = true
  }
  if (error) {
    console.log(error)
  }
  let businesses = []
  let listPagination = {}
  if (data) {
    businesses = data.businesses.data
    listPagination = data.businesses.paginatorInfo
  }
  const handlePagination = page => {
    setCurrentPage(page)
  }
  return (
    <div>
      <Breadcrumb name="Assigned Businesses" />
      <List
        type="consultant-businesses"
        title="Businesses"
        list={businesses}
        pagination={listPagination}
        pageClicked={handlePagination}
      />
      {pageLoading ? <PageLoading ripple={true} /> : null}
    </div>
  )
}

export default Businesses
