import React from 'react'
import { Route, Switch } from 'react-router-dom'
import ConsultantHome from '../Home/Home'
import ConsultantNav from '../Nav/ConsultNav'

import TaxHealth from '../Business/tax-health/TaxHealth'
import TaxHealthRequests from '../Business/tax-health/Request'
import SingleRequestDetails from '../Business/tax-health/SingleRequest'

import ComputationRequests from '../Business/tax-computation/ComputationPending'
import SingleComputation from '../Business/tax-computation/oneRequest'
import ComputationReport from '../Business/tax-computation/Computation'
import Support from '../Support/Support'
import SupportTicket from '../Support/SupportTicket'
import NewPassword from '../../SuperAdmin/Password/PasswordChange'
import PageNotFound from '../../../Components/PageNotFound/PageNotFound'
import Businesses from '../Business/index'
import SingleBusiness from '../Business/assigned-business/single-business'

const ConsultantIndex = props => {
  return (
    <div className="consultant">
      <ConsultantNav />
      <Switch>
        <Route path="/(|home)/" exact component={ConsultantHome} />
        {/*Business List*/}
        <Route
          exact
          path="/businesses"
          render={props => <Businesses {...props} />}
        />
        <Route
          path="/businesses/:slug"
          exact
          render={props => <SingleBusiness {...props} />}
        />
        {/*Health check for businesses */}
        <Route
          exact
          path="/tax-check"
          render={props => <TaxHealthRequests {...props} />}
        />

        <Route
          exact
          path="/tax-check/:id"
          render={props => <SingleRequestDetails {...props} />}
        />

        <Route
          exact
          path="/tax-check/:id/tax-health"
          render={props => <TaxHealth {...props} />}
        />
        {/* Computations for businesses*/}
        <Route
          exact
          path="/computation-requests"
          render={props => <ComputationRequests {...props} />}
        />
        <Route
          exact
          path="/computation-requests/:id"
          render={props => <SingleComputation {...props} />}
        />
        <Route
          exact
          path="/computation-requests/:id/perform-tcf"
          render={props => <ComputationReport {...props} />}
        />

        <Route path="/support" exact component={Support} />
        <Route
          path="/support/:id"
          exact
          render={props => <SupportTicket {...props} />}
        />
        {<Route exact path="/password/reset" component={NewPassword} />}

        <Route path="*" component={PageNotFound} />
      </Switch>
    </div>
  )
}

export default ConsultantIndex
