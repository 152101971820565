import React from 'react'
import './index.css'

const input = props => {
  let inputElement = null
  const inputClass = []
  if (props.invalid && props.shouldValidate && props.touched) {
    inputClass.push('invalid-field')
  }

  switch (props.elementType) {
    case 'input':
      inputElement = (
        <input
          className={inputClass}
          {...props.elementConfig}
          value={props.value}
          onChange={props.inputChanged}
        />
      )
      break
    case 'textarea':
      inputElement = (
        <textarea
          className={inputClass}
          {...props.elementConfig}
          value={props.value}
          onChange={props.inputChanged}
        />
      )
      break
    case 'select':
      inputElement = (
        <select
          className={inputClass}
          onChange={props.inputChanged}
          value={props.value}
        >
          <option></option>
          {props.elementConfig.options.map(option => (
            <option key={option.value} value={option.value}>
              {option.displayText}
            </option>
          ))}
        </select>
      )
      break
    default:
      inputElement = (
        <input
          className={inputClass}
          {...props.elementConfig}
          value={props.value}
          onChange={props.inputChanged}
        />
      )
      break
  }

  return (
    <div className="input-box">
      <label>{props.label}</label>
      {inputElement}
      <div className="error">Ouch. Wrong password. Please try again.</div>
    </div>
  )
}

export default input
