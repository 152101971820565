import React, { useState } from 'react'

import List from '../../../../../Components/List/index'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import PageLoading from '../../../../../Components/PageLoading/PageLoading'

const NEW_TIER_BUSINESSES = gql`
  query changed_TierBusiness($first: Int!, $page: Int!) {
    changed_tier(first: $first, page: $page) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
      data {
        id
        name
        slug
        type
        created_at
        employees
        tier
      }
    }
  }
`
const NewTierBusinesses = () => {
  const [currentPage, setCurrentPage] = useState(1)
  let pageLoading = false
  const { loading, error, data } = useQuery(NEW_TIER_BUSINESSES, {
    variables: { first: 6, page: currentPage }
  })
  if (loading) {
    pageLoading = true
  }
  // console.log(data)
  if (error) {
    return (
      <div className="dashform-box">
        <div className="dashform">
          <div className="dashform-intro text-center">
            Well, this is embarrassing. Something went wrong. Please try again later.
          </div>
        </div>
      </div>
    )
  }
  let changedBusinesses = []
  let listPagination = {}
  if (data && data.changed_tier) {
    changedBusinesses = data.changed_tier.data
    listPagination = data.changed_tier.paginatorInfo
  }
  const handlePagination = page => {
    setCurrentPage(page)
  }

  return (
    <div>
      {/*<Breadcrumb name="Businesses" /> */}

      <List
        type="businesses"
        title="Changed Tier"
        list={changedBusinesses}
        pagination={listPagination}
        pageClicked={handlePagination}
      />
      {pageLoading ? <PageLoading ripple={true} /> : null}
    </div>
  )
}
export default NewTierBusinesses
