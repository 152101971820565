import React, { useState } from 'react'
import gql from 'graphql-tag'
import { Link } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'
import Breadcrumb from '../../../Components/Breadcrumb'
import PageLoading from '../../../Components/PageLoading/PageLoading'

const CREATE_SUBSCRIPTION = gql`
  mutation CreateSubscription($input: createSubInput!) {
    create_subscription(input: $input)
  }
`
let pageLoading = false
let isSubscribtion = false

const CreateSubscription = () => {
  const [createSubscription, setCreateSubscription] = useState({
    business: '',
    amount: 1,
    start: '2021-01-01',
    end: '2021-01-01'
  })

  const [createSubscriptions, { loading, error, data }] = useMutation(
    CREATE_SUBSCRIPTION
  )

  if (loading) {
    pageLoading = true
  }

  if (error) {
    return (
      <div className="dashform-box">
        <div className="dashform">
          <div className="dashform-intro text-center">
            Well, this is embarrassing. Something went wrong. Please try again later.
          </div>
        </div>
      </div>
    )
  }

  const handleChange = e => {
    setCreateSubscription({
      ...createSubscription,
      [e.target.name]: e.target.value
    })
  }

  const handleSubmit = e => {
    e.preventDefault()
    createSubscriptions({
      variables: {
        input: createSubscription
      }
    })
  }

  if (data) {
    isSubscribtion = true
    pageLoading = false
  }

  return (
    <div>
      <Breadcrumb name="Create Subscriptions" />
      {!isSubscribtion ? (
        <form onSubmit={handleSubmit} className="dashform-box w-50">
          <div className="dashform update-profile">
            <div className="dashform-intro">Create Subscriptions</div>
            <label>Business</label>
            <input
              type="text"
              value={createSubscription.business}
              onChange={handleChange}
              required
              name="business"
            />
            <p className="error"></p>
            <label>Amount</label>
            <input
              type="number"
              value={createSubscription.amount}
              required
              onChange={handleChange}
              name="amount"
            />
            <p className="error"></p>
            <label className="">Start Date</label>
            <input
              type="date"
              value={createSubscription.start}
              required
              onChange={handleChange}
              name="start"
            />
            <label className="mails">End Date</label>
            <input
              type="date"
              value={createSubscription.end}
              required
              onChange={handleChange}
              name="end"
            />
            <p className="error"></p>

            <div className="submit-box">
              <button type="submit">Submit</button>
            </div>
          </div>
        </form>
      ) : (
        <div className="dashform-box">
          <div className="dashform">
            <div className="dashform-intro text-center">
              Subscription created successfully
            </div>
            
            <div className="dashform-message">
              Go back to
              <Link to="/subscriptions" className="link a">
                Subscriptions
              </Link>
            </div>
          </div>
        </div>
      )}

      {pageLoading ? <PageLoading ripple={true} /> : null}
    </div>
  )
}

export default CreateSubscription
