import React, { useState } from 'react'
import Breadcrumb from '../../../Components/Breadcrumb'
import './index.css'
import gql from 'graphql-tag'
import { Link } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'
import PageLoading from '../../../Components/PageLoading/PageLoading'
const NEW_ADMIN = gql`
  mutation NewAdmin($input: registerAdminInput!) {
    register_admin(input: $input) {
      id
      first_name
      last_name
      email
    }
  }
`

const ALL_ADMINS = gql`
  query all_Admins($first: Int!, $page: Int!) {
    admins(first: $first, page: $page) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
        __typename
      }
      data {
        id
        first_name
        last_name
        email
        created_at
        updated_at
        roles {
          name
        }
      }
    }
  }
`
const NewAdmin = props => {
  const [newAdmin, setNewAdmin] = useState({
    first_name: '',
    last_name: '',
    email: '',
    isValid: false
  })
  const [currentPage] = useState(1)
  let pageLoading = false
  let isAdmin = false
  const [registerAdmins, { data, error, loading }] = useMutation(NEW_ADMIN, {
    refetchQueries: [
      { query: ALL_ADMINS, variables: { first: 10, page: currentPage } }
    ]
  })
  if (loading) {
    pageLoading = true
  }
  if (error) {
    console.log(error)
  }
  if (data) {
    isAdmin = true
  }
  const handleChange = e => {
    setNewAdmin({
      ...newAdmin,
      [e.target.name]: e.target.value,
      isValid: checkValidity()
    })
    // console.log(newAdmin)
  }

  const checkValidity = () => {
    let valid = true
    valid = newAdmin.first_name.trim() !== '' && valid
    valid = newAdmin.last_name.trim() !== '' && valid
    valid = newAdmin.email.trim() !== '' && valid
    return valid
  }

  const handleFormSubmit = e => {
    e.preventDefault()
    // const isToValid = valid()
    const adminDetails = {
      first_name: newAdmin.first_name,
      last_name: newAdmin.last_name,
      email: newAdmin.email
    }

    registerAdmins({ variables: { input: adminDetails } })
    // setNewAdmin({ first_name: '', last_name: '', email: '' })

    // props.history.push('/admins')
  }
  return (
    <div>
      <Breadcrumb name="New Admin" />
      {!isAdmin ? (
        <form onSubmit={handleFormSubmit} className="dashform-box w-50">
          <div className="dashform update-profile">
            <div className="dashform-intro">Create Admin</div>
            <label>Firstname</label>
            <input
              type="text"
              name="first_name"
              value={newAdmin.first_name}
              onChange={handleChange}
              // required
            />

            <label>Lastname</label>
            <input
              type="text"
              name="last_name"
              value={newAdmin.last_name}
              onChange={handleChange}
              // required
            />

            <label className="mail">Email</label>
            <input
              type="email"
              name="email"
              value={newAdmin.email}
              onChange={handleChange}
            />

            <div className="submit-box">
              <button type="submit" disabled={!newAdmin.isValid}>
                Submit
              </button>
            </div>
          </div>
        </form>
      ) : (
        <div className="dashform-box">
          <div className="dashform">
            <div className="dashform-intro text-center">
              Admin created successfully
            </div>
            <div className="dashform-message">
              Go back to
              <Link to="/admins" className="link a">
                Admins
              </Link>
            </div>
          </div>
        </div>
      )}
      {pageLoading ? <PageLoading ripple={true} /> : null}
    </div>
  )
}

export default NewAdmin
