import React from 'react'
import PageLoading from '../../../../../Components/PageLoading/PageLoading'
import gql from 'graphql-tag'
import { useQuery, useMutation } from '@apollo/react-hooks'

const GET_ASSIGNABLE_CONSULTANTS = gql`
  query assignableConsultants($tier: String!) {
    assignable_consultants(tier: $tier) {
      id
      first_name
      last_name
      assignments
    }
  }
`
const CHANGE_CONSULTANT = gql`
  mutation change_Consultant($consultant_id: Int!, $business_slug: String!) {
    change_consultant(
      consultant_id: $consultant_id
      business_slug: $business_slug
    )
  }
`
const ONE_BUSINESS = gql`
  query one_Business($slug: String!) {
    business(slug: $slug) {
      id
      user_id
      name
      slug
      type
      employees
      turnover
      designation
      subscribed
      rc_no
      bn_no
      tin
      vat_reg_no
      address
      tier
      verified
      created_at
      updated_at
      cac_docs {
        files
      }
    }
  }
`

const ChangeConsultant = props => {
  const { tier, businessSlug} = props
  const { loading, error, data } = useQuery(GET_ASSIGNABLE_CONSULTANTS, {
    variables: { tier: tier }
  })
  const [
    changeConsultant,
    { data: assignData, loading: assignLoading }
  ] = useMutation(CHANGE_CONSULTANT, {
    refetchQueries: [{ query: ONE_BUSINESS, variables: { slug: businessSlug } }]
  })
  let pageLoading = false
  console.log(props)

  let isAssigned = false
  if (loading) {
    return (pageLoading = true)
  }
  if(error){
    console.log(error)
  }
  if (assignData) {
    isAssigned = true
  }
  if (assignLoading) {
    pageLoading = true
  }

  const { assignable_consultants } = data
  // console.log(assignable_consultants)
  return (
    <>
      <div className="modal-intro">
        <b>Select a new consultant</b> for{' '}
        <b className="name">{props.businessName}</b>
        <div className="modal-headers">
          <div className="w-50">Name</div>
          <div className="w-20">Businesses</div>
        </div>
      </div>
      <div className="modal-list">
        {!isAssigned ? (
          <>
            {assignable_consultants.map((consultant, key) => (
              <div className="modal-list-item" key={key}>
                <div className="w-50">
                  {consultant.first_name} {consultant.last_name}
                </div>
                <div className="w-20">{consultant.assignments}</div>
                <div className="action w-30">
                  <button
                    onClick={() => {
                      changeConsultant({
                        variables: {
                          business_slug: businessSlug,
                          consultant_id: consultant.id
                        }
                      })
                    }}
                  >
                    Select
                  </button>
                </div>
              </div>
            ))}
          </>
        ) : (
          <div className="dashform-box">
            <div className="dashform">
              <div className="dashform-message">
                Business updated
                <br />
                <br />
                <br />
              </div>
            </div>
          </div>
        )}
      </div>
      {pageLoading ? <PageLoading ripple={true} /> : null}
    </>
  )
}

export default ChangeConsultant
