import React, { useState } from 'react'
import Breadcrumb from '../../../Components/Breadcrumb'
import { Link } from 'react-router-dom'
import gql from 'graphql-tag'
import { useQuery, useMutation } from '@apollo/react-hooks'
import PageLoading from '../../../Components/PageLoading/PageLoading'
import './plans.css'

const SINGLE_PLAN = gql`
  query singlePlan($id: Int!) {
    plan(id: $id) {
      id
      name
      plan_code
      description
      amount
      interval
      currency
      created_at
      updated_at
    }
  }
`

const PLANS = gql`
  query {
    plans {
      id
      name
      plan_code
      description
      amount
      interval
      currency
      created_at
      updated_at
    }
  }
`
const DELETE_PLAN = gql`
  mutation delete_Plan($id: Int!) {
    delete_plan(id: $id)
  }
`
const SinglePlan = props => {
  const {
    match: {
      params: { id }
    }
  } = props

  let planId = parseInt(id)
  
  const { data: planData, loading, error } = useQuery(SINGLE_PLAN, {
    variables: {
      id: planId
    }
  })
  
  const [deletePlan] = useMutation(DELETE_PLAN, {
    refetchQueries: [{ query: PLANS }]
  })
  
  const [timePressed, setTimePressed] = useState(0)
  let pageLoading = false
  let plan = {}

  if (loading) {
    pageLoading = true
  }

  if (error) {
    return (
      <div className="dashform-box">
        <div className="dashform">
          <div className="dashform-intro text-center">
            Well, this is embarrassing. Something went wrong. Please try again later.
          </div>
        </div>
      </div>
    )
  }
  
  if (planData) {
    plan = planData.plan
  }
  
  const onPress = () => {
    setTimePressed(timePressed + 1)
    if (timePressed === 1) {
      deletePlan({
        variables: {
          id: planId
        }
      })
      props.history.push('/plans')
    }
  }
  const text = ['Delete Plan', 'Confirm Delete']

  return (
    <div className="customer-box">
      {planData ? (
        <>
          <Breadcrumb name="Plan" />
          <div className="profile-box">
            <div className="profile">
              <div className="profile-intro">
                <div className="desc big">
                  <div className="label">Name</div>
                  <div className="value">{plan.name}</div>
                </div>

                <div className="desc wide">
                  <div className="label">Description</div>
                  <div className="value">{plan.description}</div>
                </div>
                
                <div className="desc">
                  <div className="label">Amount</div>
                  <div className="value">
                    {' '}
                    <span className="naira bold">&#8358;</span>
                    {plan.amount}
                  </div>
                </div>
                
                <div className="desc">
                  <div className="label">Created At</div>
                  <div className="value">
                    {new Date(plan.created_at).toDateString()}
                  </div>
                </div>
                
                <div className="desc">
                  <div className="label">Updated At</div>
                  <div className="value">
                    {new Date(plan.updated_at).toDateString()}
                  </div>
                </div>

                <div className="desc">
                  <div className="label">Interval</div>
                  <div className="value">{plan.interval}</div>
                </div>
                
                <div className="desc">
                  <div className="label">Plan Code</div>
                  <div className="value">{plan.plan_code}</div>
                </div>

                <div className="profile-links">
                  <Link to={`/plans/${Number(plan.id)}/edit`}>
                    {' '}
                    <button
                      className="profile-link b purple"
                      // onClick={editPlan}
                    >
                      Edit plan
                    </button>
                  </Link>

                  <button className="profile-link b purple" onClick={onPress}>
                    {text[timePressed]}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      
      ) : null}

      {pageLoading ? <PageLoading ripple={true} /> : null}
    </div>
  )
}

export default SinglePlan
