import React from 'react'
import './index.css'
import { Route, Redirect } from 'react-router-dom'
import Login from './Login'
import { useQuery } from '@apollo/react-hooks'
import ForgotPassword from './ForgotPassword/index'
import gql from 'graphql-tag'
const IS_LOGGED_IN = gql`
  query IsUserLoggedIn {
    token @client
  }
`
const AuthPages = () => {
  const { data: loggedIn } = useQuery(IS_LOGGED_IN)
  if (loggedIn.isLoggedIn) {
    return <Redirect to="/" />
  }
  return (
    <div className="auth-page">
      <div className="auth-form">
        <Route path="/auth" exact component={Login} />
        {/*<Route path="/signup" exact component={Signup} >*/}
        <Route path="/auth/forgot-password" exact component={ForgotPassword} />
      </div>
    </div>
  )
}

export default AuthPages
